import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import about from "@/views/About.vue";
import details from "@/views/Details.vue";
import login from "@/views/Login.vue";
import register from "@/views/Register.vue";
import vip from "@/views/Vip.vue";
import order from "@/views/Order.vue";

Vue.use(VueRouter)


const routes = [
    {path: '/', component: Home},
    {path: '/about', component: about},
    {path: '/details', component: details},
    {path: '/login', component: login},
    {path: '/register', component: register},
    {path: '/vip', component: vip},
    {path: '/order', component: order},
]

const router = new VueRouter({
    routes
})

// to 去哪个路由；from 从哪里来；next() 放行
router.beforeEach((to, from, next) => {
    // 如果访问的是 需要登录的 判断 登录
    if (to.path === '/admin'){
        //获取用户页面token信息
        const userinfo = localStorage.getItem('userinfo');
        //如果数据为null则跳转到指定路径
        if (!userinfo) return next("/login")
    }
    next() // 放行
})

export default router
