<template>
    <el-header class="header" height="80">
        <el-row :gutter="20" type="flex" justify="center" align="middle">
            <el-col :span="8" :offset="0" class="text-left">
                <el-image
                    :src="logoUrl"
                    fit="contain"
                    class="navigationBarLogo"
                ></el-image>
            </el-col>
            <el-col :span="9" :pull="1">
                <el-input
                    placeholder="请输入源码关键字后回车"
                    v-model="searchKey"
                    @keyup.enter.native="search"
                    prefix-icon="el-icon-search">
                    <i slot="suffix" class="el-input__icon el-icon-close" @click="clearSearchBox"></i>
                </el-input>
            </el-col>
            <el-col :span="9" class="text-left">
                <el-row>
                    <el-col :span="10"><span class="f14">
                        共有资源：<el-link :underline="false"
                                          class="f16 text-bold">{{ totalNumberOfResources }}</el-link></span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="favoriteWebsites"
                        >收藏本站
                        </el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="setAsHomepage"
                        >设为首页
                        </el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="messageBoard"
                        >留言求码
                        </el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link
                            type="primary"
                            :underline="false"
                            :href="service.webCustomerService"
                            target="_blank">联系客服
                        </el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-tooltip v-if="userinfo" class="item" effect="dark" :content="userinfo.email"
                                    placement="top">
                            <el-link
                                type="info"
                                :underline="false"
                                @click="logOut"
                            >退出登录
                            </el-link>
                        </el-tooltip>
                        <div v-else style="display: inline;">
                            <el-link
                                type="danger"
                                :underline="false">
                                <router-link :to="{ path: '/login' }">登录</router-link>
                            </el-link>
                            <el-divider direction="vertical"></el-divider>
                            <el-link
                                type="success"
                                :underline="false">
                                <router-link :to="{ path: '/register' }">注册</router-link>
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row style="color: #787878; font-size: 12px;" v-if="userinfo && userinfo.viptime">
            <template>
                <span>您好，{{ userinfo.email }}</span>
                <el-divider direction="vertical"></el-divider>
                <template v-if="isVIPExpired">
                    <span>您的VIP已到期</span>
                </template>
                <template v-else>
                    <span>您的VIP到期时间为：{{ userinfo.viptime }}</span>
                </template>
            </template>
            <el-divider direction="vertical"></el-divider>
            <span>今日免费下载次数：{{ remainingDownloads }}</span>
        </el-row>


        <!-- 留言板 -->
        <el-dialog title="留言内容" :visible.sync="dialogFormVisible">
            <el-form v-loading="submitLoad">
                <el-input type="textarea" v-model="leaveAMessage" :rows="6" placeholder="请输入内容"></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmMessage" :disabled="submitLoad">确 定</el-button>
            </div>
        </el-dialog>
    </el-header>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
import axios from "axios";

export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    props: {},
    data() {
        // 这里存放数据
        return {
            searchKey: "", // 导航栏搜索
            logoUrl: require("@/assets/logo.jpg"), // logo地址
            userinfo: '', // 登录信息
            dialogFormVisible: false, // 留言板 显示隐藏
            leaveAMessage: '', // 留言内容
            submitLoad: false, // 禁止点击按钮
            orderTodayCount: {}, // 今天用了多少次vip权限下载
            dailyFrequency: {}, // 用户VIP次数
            totalNumberOfResources: '', // 资源总数
            service: {webCustomerService: '', afterSalesCustomerService: ''}, // 客服
        }
    },
    // 计算属性 类似于 data 概念
    computed: {
        // 计算vip到期时间
        isVIPExpired() {
            if (this.userinfo.viptime) {
                const vipExpirationDate = new Date(this.userinfo.viptime);
                const currentDate = new Date();
                return vipExpirationDate < currentDate;
            }
            return false;
        },
        // 计算vip用户剩余下载次数
        remainingDownloads() {
            if (isNaN(this.dailyFrequency) || isNaN(this.orderTodayCount)) {
                return ''; // 如果数据尚未加载完成，则返回空字符串
            } else {
                return this.dailyFrequency - this.orderTodayCount;
            }
        }
    },
    // 监控 data 中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 确定 留言
        confirmMessage() {
            if (!this.leaveAMessage.trim()) {
                this.$message.warning("留言不能为空");
                return
            }
            this.$confirm('确认无误提交您的留言?', '提示', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.submitLoad = true; // 按钮加载
                // 提交留言
                axios.post('admin/api/index/addAMessage.html', {leaveAMessage: this.leaveAMessage}, {headers: {'token': this.userinfo.token}})
                    .then(res => {
                        console.log("res", res);
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    this.dialogFormVisible = false; // 隐藏留言板
                    this.submitLoad = false; // 关闭按钮加载
                    this.leaveAMessage = ''; // 清空内容
                });
            })
        },
        // 搜索
        search() {
            console.log("搜索", this.searchKey);
            // 检查当前搜索关键字是否与当前路由中的搜索关键字相同
            if (this.searchKey !== this.$route.query.searchKey) {
                this.$router.push({path: '/', query: {searchKey: this.searchKey}});
            } else {
                this.$message(`已经是 ${this.searchKey} 的搜索结果了`);
            }
        },
        // 收藏本站
        favoriteWebsites() {
            const h = this.$createElement;
            const url = window.location;
            const title = document.title;
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("360se") > -1) {
                this.$notify({
                    title: '提示',
                    message: h('i', {style: 'color: #3a8ee6'}, '由于360浏览器功能限制，请按 Ctrl+D 手动收藏！'),
                });
            } else if (ua.indexOf("msie 8") > -1) {
                window.external.AddToFavoritesBar(url, title); //IE8
            } else if (document.all) {//IE类浏览器
                try {
                    window.external.addFavorite(url, title);
                } catch (e) {
                    this.$notify({
                        title: '提示',
                        message: h('i', {style: 'color: #3a8ee6'}, '您的浏览器不支持,请按 Ctrl+D 手动收藏!'),
                    });
                }
            } else if (window.sidebar) {//firfox等浏览器；
                window.sidebar.addPanel(title, url, "");
            } else {
                this.$notify({
                    title: '提示',
                    message: h('i', {style: 'color: #3a8ee6'}, '您的浏览器不支持,请按 Ctrl+D 手动收藏!'),
                });
            }

        },
        // 留言求码
        messageBoard() {
            if (this.userinfo) {
                this.dialogFormVisible = true; // 如果登录，显示留言板
            } else {
                this.$message.warning("请先登录账号");
            }
        },
        // 设为首页
        setAsHomepage() {
            const h = this.$createElement;
            this.$notify({
                title: '浏览器安全',
                message: h('i', {style: 'color: #e6a23c'}, '您的浏览器不支持自动设置,请前往浏览器设置页面手动操作!'),
            });
        },
        // 清除搜索框
        clearSearchBox() {
            this.searchKey = '';
            this.$router.push({path: '/'});
        },
        // 退出登录
        logOut() {
            this.$confirm('确定要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 删除用户信息
                localStorage.removeItem('userinfo')
                //获取用户信息
                const userinfo = localStorage.getItem('userinfo');
                //如果数据为null
                if (!userinfo) {
                    this.$message.success("已退出登录");
                    this.$router.push({path: '/'});
                } else {
                    this.$message.error("退出失败");
                }
                location.reload() // 刷新页面
            })
        },
        // 查询今日使用VIP权限下载过多少次
        orderToday() {
            //如果用户数据为null
            if (!this.userinfo) {
                return
            }
            axios.post('admin/api/index/orderToday.html', '', {headers: {'token': this.userinfo.token}})
                .then(res => {
                    this.dailyFrequency = res.data.data.dailyFrequency;
                    this.orderTodayCount = res.data.data.orderTodayCount;
                    this.totalNumberOfResources = res.data.data.totalNumberOfResources;
                    localStorage.setItem('orderTodayCount', this.orderTodayCount);
                    console.log("用户VIP总次数", this.dailyFrequency);
                    console.log("今日已使用", this.orderTodayCount);
                    console.log("资源总数", this.totalNumberOfResources);
                });
        },
        // 查询资源总数
        totalNumberOfQueryResources() {
            axios.post('admin/api/index/totalNumberOfQueryResources.html')
                .then(res => {
                    this.totalNumberOfResources = res.data.data.totalNumberOfResources;
                    console.log("资源总数", this.totalNumberOfResources);
                });
        },
        // 获取logo信息
        getLogo(){
            axios.post('admin/api/index/getLogo.html')
                .then(res => {
                    this.logoUrl = res.data.data.logo;
                    console.log("logo", res.data.data.logo);
                });
        },
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        // 用户信息
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
        console.log("用户信息", this.userinfo);
        this.orderToday() // 查询今日使用VIP权限下载过多少次
        this.totalNumberOfQueryResources() // 查询资源总数
        // 获取客服信息
        axios.post('admin/api/index/getService.html')
            .then(res => {
                this.service = res.data.data;
            });
        this.getLogo() // 获取logo信息
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
    },
    beforeCreate() {
    },
    beforeMount() {
    }, // 生命周期 - 挂载之前
    beforeUpdate() {
    }, // 生命周期 - 更新之前
    updated() {
    }, // 生命周期 - 更新之后
    beforeDestroy() {
    }, // 生命周期 - 销毁之前
    destroyed() {
    }, // 生命周期 - 销毁完成
    activated() {
    } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>

<style scoped>
.f18 {
    font-size: 18px;
}

.f16 {
    font-size: 16px;
}

.f14 {
    font-size: 14px;
}

.text-left {
    text-align: left
}

.text-bold {
    font-weight: bold
}

.text-red {
    color: #ff0000
}

.navigationBarLogo {
    max-width: 250px;
    max-height: 80px;
}

.header {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: #585858;
}

/deep/ .el-breadcrumb__inner.is-link {
    line-height: 20px;
}

/deep/ .is-link {
    cursor: pointer !important;
}

</style>
