<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-steps :active="active" finish-status="success" align-center>
                    <el-step title="选择要爬取的网站"></el-step>
                    <el-step :title="key"></el-step>
                    <el-step title="完成"></el-step>
                </el-steps>
                <!--  步骤三 -->
                <el-col :span="6" :offset="9" style="height: 230px;padding-top: 0px" v-if="active == 2">
                    <el-result icon="success" title="成功提示" subTitle="您已完成趴取操作">
                    </el-result>
                </el-col>
                <!--  步骤一 -->
                <el-col :span="6" :offset="1" style="height: 300px;padding-top: 80px" v-if="active == 0">
                    <el-cascader
                        :options="options"
                        :props="{ checkStrictly: true }"
                        v-model="select"
                        clearable
                    ></el-cascader>
                </el-col>
                <!--  步骤二 -->
                <el-col :span="20" :offset="2" v-if="active == 1" style="padding-top: 30px">
                    <el-alert
                        style="margin-bottom: 20px"
                        :title="briefIntroduction"
                        type="success"
                        :closable="false"
                        show-icon>
                    </el-alert>
                    <el-col :span="13" :pull="1">
                        <el-button
                            style="margin-bottom: 20px"
                            @click="abortCrawling"
                            type="danger"
                            size="mini"
                        >趴完这一页后中止趴取
                        </el-button>
                        <span style="margin-left: 10px;color: #585858;font-size: 13px">自定义起始页码:</span><el-input-number style="margin-left: 10px;" :min="0" v-model="customPage" type="" placeholder="自定义起始页码" size="mini"></el-input-number>
                    </el-col>

                    <el-table
                        v-loading="loading"
                        :data="waitingToBePickedUp"
                        :border="true"
                        style="width: 100%">
                        <el-table-column
                            type="index"
                            :index="indexMethod"
                            width="30">
                        </el-table-column>
                        <el-table-column
                            prop="url"
                            label="网址">
                        </el-table-column>
                        <el-table-column
                            prop="totalPage"
                            label="页数"
                            width="70">
                        </el-table-column>
                        <el-table-column
                            prop="totalNumber"
                            label="总数"
                            width="100">
                        </el-table-column>
                        <el-table-column label="操作" width="200">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    @click="getTotalPage(scope.$index, scope.row)">获取页码
                                </el-button>
                                <el-button
                                    size="mini"
                                    v-if="scope.row.totalNumber"
                                    type="primary"
                                    @click="startCrawling(scope.$index, scope.row)"
                                    :disabled="startCrawlingButton">开始趴取
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div style="height: 30px"></div>
                    <div style="height: 200px;width: 400px;margin: auto; overflow-y: auto;text-align: left">
                        <div v-for="(item,index) in failedToCrawl" :key="index">
                            <el-tag type="warning" style="margin-bottom: 10px">失败：{{ item }}</el-tag>
                        </div>
                    </div>
                    <div style="height: 30px"></div>
                    <!--                        <el-input v-model="input" placeholder="输入爬取的地址"></el-input>-->
                    <!--                        <el-button type="success" @click="getData(input)">爬取</el-button>-->
                </el-col>


                <el-col :span="24">
                    <el-button @click="previousStep">上一步</el-button>
                    <el-button @click="next" type="primary" plain>下一步</el-button>
                </el-col>
            </el-col>

        </el-row>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;  // 允许axios请求携带cookie等凭证
import $ from 'jquery'; // 神中神のjq

export default {
    components: {},
    data() {
        return {
            input: 'yuanmadx/youxi/list_1.html',
            active: 0, // 步骤条
            loading: false, // 加载状态
            options: [
                {
                    value: 'yuanmadx',
                    label: '源码当先',
                    children: [
                        {value: 'youxi', label: '游戏源码',},
                        {value: 'wangzhan', label: '网站源码'},
                        {value: 'app', label: 'APP源码'},
                        {value: 'weixin', label: '微信源码'},
                        {value: 'moban', label: '模板源码'},
                    ]
                },
                {value: 'nsxu', label: 'NSXU源码社',},
                {value: 'mitaobo', label: '蜜桃源码网',},
                {value: 'jiufuquan', label: '玖富圈'},
                {value: 'yuanmawu', label: '源码屋'},
                {value: '8yweb', label: '八爷源码'},
                {value: 'timibbs', label: '天美社区'},
                {value: 'wusuo8', label: '吾索源码网'},
                {value: 'wusuo8', label: '吾索源码网'},
                {value: '515ymw', label: '515源码'},
            ],
            select: '', // 选取到的值
            briefIntroduction: '', // 简介
            waitingToBePickedUp: [], // 待趴取数据
            failedToCrawl: [], // 趴取失败的
            startCrawlingButton: false, // 开始趴取按钮
            abortFlag: false, // 中止趴取的标志
            addingData: false, // 是否正在添加数据的标志
            dataQueue: [], // 数据队列
            key:'等待选择', // 关键字
            customPage:0, // 自定义页码
            nsxuRequestBody:{
                post_type: 'post-1',
                post_order: 'new',
                post_row_count: 5,
                post_count: 1,
                post_thumb_width: 190,
                post_thumb_ratio_pc: '1/0.74',
                post_thumb_width_mobile: 100,
                post_thumb_ratio_mobile: '1/0.6',
                post_title_row: 1,
                post_title_row_mobile: 2,
                post_thumb_ratio: '1/0.618',
                post_open_type: 0,
                post_meta: [
                    'user',
                    'date',
                    'des',
                    'like',
                    'views',
                    'download',
                    'hide'
                ],
                post_paged: 5,
                post_load_more: 0,
                post_cat: [1],
                show_sidebar: 0,
                width: 1200,
                no_rows: false,
                paged: 1
            }, // NSXU源码社的请求体
            requestBody515ymw:{
                post_type: 'post-1',
                post_order: 'new',
                post_row_count: 5,
                post_count: 1,
                post_thumb_width: 190,
                post_thumb_ratio_pc: '1/0.74',
                post_thumb_width_mobile: 100,
                post_thumb_ratio_mobile: '1/0.6',
                post_title_row: 1,
                post_title_row_mobile: 2,
                post_thumb_ratio: '1/0.618',
                post_open_type: 0,
                post_meta: [
                    'user',
                    'date',
                    'des',
                    'like',
                    'views',
                    'download',
                    'hide'
                ],
                post_paged: 5,
                post_load_more: 0,
                post_cat: [1],
                show_sidebar: 0,
                width: 1200,
                no_rows: false,
                paged: 1
            }, // 515ymw请求体
        }
    },
    methods: {
        // 中止趴取
        abortCrawling() {
            this.abortFlag = true; // 设置中止标志为 true
        },
        async getData(url) {
            url = url.replace("*", "1")
            const h = this.$createElement;
            this.loading = true; // 开始请求时设置加载状态为 true
            this.$notify({
                title: '爬取页码',
                message: h('i', {style: 'color: orangered'}, `开始爬取 ${url}`)
            });
            try {
                const response = await axios.get(url);
                // console.log(response.data);
                // 在这里进行后续的逻辑操作
                this.$message({
                    message: '本次爬取成功',
                    type: 'success'
                });
                return response.data
            } catch (error) {
                console.error('爬取页码失败', error);
                // 如果请求失败将在这里进行后续的逻辑操作
                this.$message.error('爬取页码失败');
            }
            this.loading = false; // 请求结束后设置加载状态为 false
        },
        // 表格索引
        indexMethod(index) {
            return index + 1;
        },
        // 步骤条下一步
        next() {
            if (this.select == '') {
                this.$message({
                    message: '选取后才能进行下一步',
                    type: 'warning'
                });
                return;
            }
            console.log('选中', this.select)
            if (this.active++ >= 2)this.active = 0;
            console.log("",this.active);
            if (this.active == 1) { // 选择完毕
                this.customPage = 0; // 初始自定义页码
                this.key = this.select[0]; // 关键字赋值给key
                this.briefIntroduction = `准备爬取 ${this.select}`
                this.waitingToBePickedUp.splice(0); // 清空待趴取列表
                this.failedToCrawl.splice(0); // 清空失败列表
                if (this.select.includes("yuanmadx")) {
                    this.briefIntroduction = `您选择了爬取【源码当先】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'yuanmadx/youxi/list_*.html'});
                    this.waitingToBePickedUp.push({url: 'yuanmadx/wangzhan/list_*.html'});
                    this.waitingToBePickedUp.push({url: 'yuanmadx/app/list_*.html'});
                    this.waitingToBePickedUp.push({url: 'yuanmadx/weixin/list_*.html'});
                    this.waitingToBePickedUp.push({url: 'yuanmadx/moban/list_*.html'});
                }else if (this.select.includes("nsxu")){
                    this.briefIntroduction = `您选择了爬取【NSXU源码社】的全部页面。以下是他的API接口`;
                    this.waitingToBePickedUp.push({url: 'https://www.nsxu.com/wp-json/b2/v1/getPostList'});
                }else if(this.select.includes("mitaobo")){
                    this.briefIntroduction = `您选择了爬取【蜜桃源码网】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'mitaobo/wxym/page/*'});
                    this.waitingToBePickedUp.push({url: 'mitaobo/yxym/page/*'});
                    this.waitingToBePickedUp.push({url: 'mitaobo/app/page/*'});
                    this.waitingToBePickedUp.push({url: 'mitaobo/wzym/page/*'});
                    this.waitingToBePickedUp.push({url: 'mitaobo/spjc/page/*'});
                    this.waitingToBePickedUp.push({url: 'mitaobo/wzbk/page/*'});
                    this.waitingToBePickedUp.push({url: 'mitaobo/mf/page/*'});
                }else if(this.select.includes("jiufuquan")){
                    this.briefIntroduction = `您选择了爬取【玖富圈】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=65&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=6&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=5&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=49&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=67&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=76&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=77&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=88&page=*'});
                    this.waitingToBePickedUp.push({url: 'jiufuquan/e/action/ListInfo/?classid=34&page=*'});
                }else if(this.select.includes("yuanmawu")){
                    this.briefIntroduction = `您选择了爬取【源码屋】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'yuanmawu/category/website/page/*'});
                }else if(this.select.includes("8yweb")){
                    this.briefIntroduction = `您选择了爬取【八爷源码】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: '8yweb/page_*.html'});
                }else if(this.select.includes("timibbs")){
                    this.briefIntroduction = `您选择了爬取【天美社区】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'timibbs/page/*'});
                }else if(this.select.includes("wusuo8")){
                    this.briefIntroduction = `您选择了爬取【吾索源码网】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'wusuo8/list-1-*.html'});
                    this.waitingToBePickedUp.push({url: 'wusuo8/list-22-*.html'});
                    this.waitingToBePickedUp.push({url: 'wusuo8/list-4-*.html'});
                    this.waitingToBePickedUp.push({url: 'wusuo8/list-3-*.html'});
                    this.waitingToBePickedUp.push({url: 'wusuo8/list-28-*.html'});
                    this.waitingToBePickedUp.push({url: 'wusuo8/type/61/'});
                    this.waitingToBePickedUp.push({url: 'wusuo8/index.php?m=content&c=index&a=lists&catid=35&page=*'});
                }else if(this.select.includes("515ymw")){
                    this.briefIntroduction = `您选择了爬取【515源码】的全部页面。以下是将要爬取的页面`;
                    this.waitingToBePickedUp.push({url: 'https://515ymw.cc/licai/view*.html'});
                    this.waitingToBePickedUp.push({url: 'https://515ymw.cc/qukuai/view*.html'});
                    this.waitingToBePickedUp.push({url: 'https://515ymw.cc/qita/view*.html'});
                }
                this.loading = false; // 设置加载状态为 false
            }
        },
        // 步骤条上一步
        previousStep() {
            if (this.active-- <= 0) {
                this.active = 0;
                this.$message({
                    message: '已经是第一步了',
                    type: 'warning'
                });
            }
        },
        // 获取页码
        async getTotalPage(index, row) {
            // const h = this.$createElement;
            this.loading = true; // 开启表格加载状态
            // console.log(index, row)
            // 源码当先 #############################
            if(this.key == 'yuanmadx'){
                const sourceCode = await this.getData(row.url); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // jq解析页面

                const totalPage = html.find("#pageGroup > span > strong:nth-child(1)").text()
                const totalNumber = html.find("#pageGroup > span > strong:nth-child(2)").text()
                console.log("页数", totalPage)
                console.log("总数", totalNumber)
                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', totalNumber);
                // NSXU源码社 ############################
            }
            else if (this.key == 'nsxu'){
                this.$message.warning('开始请求api');
                // 请求API
                axios.post(row.url, this.nsxuRequestBody)
                    .then(res => {
                        console.log("api返回", res)
                        this.$message({
                            message: '请求对方API成功',
                            type: 'success'
                        });

                        console.log("页数", res.data.pages)
                        console.log("总数", res.data.count)
                        // 赋值页码// 使用 this.$set 添加新属性
                        this.$set(this.waitingToBePickedUp[index], 'totalPage', res.data.pages);
                        this.$set(this.waitingToBePickedUp[index], 'totalNumber', res.data.count);
                    }).catch(error => {
                    console.error(error);
                    this.$message.error('爬取页码失败');
                });
                // ##  蜜桃源码网  ############
            }
            else if (this.key == 'mitaobo'){
                this.$message.warning(`开始趴取${row.url}`);

                const sourceCode = await this.getData(row.url.replace(/\/page\/.*$/, '')); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // jq解析页面

                const matches = html.find("div.pagination.justify-content-center > span").text().match(/\/(\d+)/);
                const totalPage = matches ? matches[1] : null;

                const totalNumber = parseInt(totalPage, 10) * 10;
                console.log("页数", totalPage)
                console.log("总数", totalNumber)
                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', `≈${totalNumber}`);
                // ### 玖富圈 ##############
            }
            else if (this.key == 'jiufuquan'){  // ### 玖富圈 ##############
                this.$message.warning(`开始趴取${row.url}`);
                const sourceCode = await this.getData(row.url.replace("*", "0")); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // 使用 jQuery 解析页面源代码

                // 获取指定选择器下的第一个 a 标签的 href 属性值
                const href = html.find('.nav-links > a').last().attr('href'); // 这里我使用了 last() 方法来获取最后一个 a 标签的 href 属性

                // 使用正则表达式提取出 href 属性值中的页码
                const totalPageMatch = href.match(/page=(\d+)/); // 匹配 page= 后面的数字部分
                const totalPage = totalPageMatch ? parseInt(totalPageMatch[1]) : 0; // 获取页数，并将结果转换为整数

                // 计算总数
                const totalNumber = (totalPage + 1) * 20;

                console.log("页数", totalPage);
                console.log("总数", totalNumber);


                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', `≈${totalNumber}`);

                // ########## 源码屋 #######################
            }
            else if (this.key == 'yuanmawu'){// ########## 源码屋 #######################
                this.$message.warning(`开始趴取${row.url}`);
                const sourceCode = await this.getData(row.url.replace(/\/page\/.*$/, '')); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // 使用 jQuery 解析页面源代码

                const totalPage = html.find('div.pagination > ul > li:nth-child(6) > a').text();
                const totalNumber = totalPage*24;

                console.log("页数", totalPage);
                console.log("总数", totalNumber);


                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', `≈${totalNumber}`);

                // ########## 八爷源码 #######################
            }
            else if (this.key == '8yweb'){// ########## 八爷源码 #######################
                this.$message.warning(`开始趴取${row.url}`);
                const sourceCode = await this.getData(row.url.replace('/page_*.html', '')); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // 使用 jQuery 解析页面源代码

                const href = html.find('div.numeric-pagination ul li:last-child a').last().attr('href');

                // 使用正则表达式提取出 href 属性值中的页码
                const totalPageMatch = href.match(/\/page_(\d+)\.html/); // 匹配 href 属性中的页码部分
                const totalPage = totalPageMatch ? parseInt(totalPageMatch[1]) : 0; // 获取页数，并将结果转换为整数
                // 计算总数
                const totalNumber = totalPage * 12;

                console.log("页数", totalPage);
                console.log("总数", totalNumber);

                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', `≈${totalNumber}`);

                // ########## 天美社区 #######################
            }
            else if (this.key == 'timibbs'){// ########## 天美社区 #######################
                this.$message.warning(`开始趴取${row.url}`);
                const sourceCode = await this.getData(row.url); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // 使用 jQuery 解析页面源代码

                const totalPage = parseInt(html.find('#ri_home_lastpost_widget-2 > section > nav > ul > li.page-item.page-last > a').attr('href').split('/').pop());
                const totalNumber = totalPage*20;

                console.log("页数", totalPage);
                console.log("总数", totalNumber);

                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', `≈${totalNumber}`);
            }
            else if (this.key == 'wusuo8'){// ########## 吾索源码网 #######################
                this.$message.warning(`开始趴取${row.url}`);
                const sourceCode = await this.getData(row.url); // 等待 getData 方法返回 源码
                const html = $(sourceCode); // 使用 jQuery 解析页面源代码

                let totalPage, totalNumber;
                // 检查 #pages 元素是否为空
                if (html.find('#pages').text().trim() === '') {
                    // 如果为空，则将 totalPage 和 totalNumber 都设置为 1
                    totalPage = 1;
                    totalNumber = '只有一页';
                } else {
                    // 如果不为空，则获取倒数第二个和第一个 <a> 标签的文本内容
                    totalPage = html.find('#pages > a:nth-last-child(2)').text();
                    totalNumber = html.find('#pages > a:nth-child(1)').text();
                }

                console.log("页数", totalPage);
                console.log("总数", totalNumber);


                // 赋值页码// 使用 this.$set 添加新属性
                this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                this.$set(this.waitingToBePickedUp[index], 'totalNumber', totalNumber);
            }
            else if (this.key == '515ymw'){// ########## 515源码 #######################
                this.$message.warning(`开始趴取${row.url}`);
                // const sourceCode = await this.getData(row.url); // 等待 getData 方法返回 源码
                // const html = $(sourceCode); // 使用 jQuery 解析页面源代码

                axios.get('admin/api/index/getWebPageSourceCode', { params: { url: row.url.replace("*", "1") }})
                    .then(res => {
                        const html = $(res.data.data); // 使用 jQuery 解析页面源代码

                        let totalPage, totalNumber;
                        totalPage = html.find('#list_pagination > ul:nth-last-child(2) > li:nth-last-child(2) > a').text();
                        totalPage = parseInt(totalPage.replace(/\D/g, ''), 10)
                        totalNumber = totalPage * 15;

                        console.log("页数", totalPage);
                        console.log("总数", totalNumber);
                        // 赋值页码
                        this.$set(this.waitingToBePickedUp[index], 'totalPage', totalPage);
                        this.$set(this.waitingToBePickedUp[index], 'totalNumber', totalNumber);
                    })
                    .catch(error => {
                        console.error(error);
                        this.$message.error('爬取页码失败');
                    })
                    .finally(() => {
                        this.loading = false; // 请求结束后设置加载状态为 false
                    });
            }
        },
        // 开始趴取
        async startCrawling(index, row) {
            // 禁用按钮
            this.startCrawlingButton = true;
            const h = this.$createElement;
            this.$notify({
                title: '准备趴取',
                message: h('i', {style: 'color: #8bc34a;'}, `准备趴取${row.totalPage}页，${row.totalNumber}条数据`)
            });
            if(this.key == 'yuanmadx'){ // ##源码当先##

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "源码当先"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }

                    let url = row.url.replace("*", i);
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);
                        // 循环ul
                        await Promise.all(html.find(".indexListGrid > ul > li").map(async (index, item) => {
                            const data = {
                                source: "源码当先",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("yuanmadx/", "https://www.yuanmadx.com/")
                            };
                            // 储存在数据库 的 原链接
                            const originalLink = $(item).find("div > h5 > a").attr("href");

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("div > h5 > a").text();
                            // 封面
                            data.cover = $(item).find("a > img").attr("data-funlazy");
                            // 发布日期
                            const releaseDate = $(item).find("div > p > span > .icon-shijian");
                            data.releaseDate = releaseDate.length > 0 ? releaseDate.parent().text().split(' ')[1].trim() : "";
                            // 下载量
                            const downloadVolume = $(item).find("div > p > span > .icon-xiazai");
                            data.downloadVolume = downloadVolume.length > 0 ? downloadVolume.parent().text().trim().split(' ')[1].trim() : "";
                            // 分类
                            data.classification = $(item).find("div > p > a").text();
                            // 原链接
                            data.originalLink = originalLink;
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace(/^https?:\/\/www\.yuanmadx\.com/, 'yuanmadx'));
                            const innerHtml = $(res.data);
                            // 简介
                            data.briefIntroduction = innerHtml.find("div.description > div > p").text();
                            // 内容
                            data.content = innerHtml.find("article").html().trim();
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                    }
                }
            }
            else if (this.key == 'nsxu') { // ##NSXU源码社##

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "NSXU源码社"});
                // 储存在数据库 的 原链接
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    console.log("i",i);
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        break; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        break; // 中止趴取
                    }

                    let url = row.url;
                    // 给请求体 赋值页数
                    this.nsxuRequestBody.paged = i;
                    this.nsxuRequestBody.post_paged = i;
                    try {
                        const response = await axios.post(url,this.nsxuRequestBody);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `api #${i} 请求成功`),
                            duration: 2500,
                        });
                        const html = $(response.data.data);
                        // 读取数据
                        const data = {  // 配置基础待写入数据
                            source: "NSXU源码社",
                            pageCount: i,
                            retrievePageCountLinks: url
                        };
                        // 首先获取链接，用来判断是否存在数据库
                        const originalLink = $(html).find(".post-module-thumb > a").attr("href");
                        // 判断链接是否存在于数据库中
                        const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                        if (existsInDatabase) {
                            console.log(`${originalLink} 已存在数据库中`);
                            this.$notify({
                                title: '跳过',
                                message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                            });
                            // 解除禁用按钮
                            this.startCrawlingButton = false;
                            this.loading = false; // 关闭表格加载状态
                            continue; // 如果已存在，跳过当前循环的剩余部分并进入下一次循环
                        }
                        // 原链接
                        data.originalLink = originalLink;
                        // 标题
                        data.title = $(html).find(".post-info > h2 > a").text();
                        // 封面
                        data.cover = $(html).find(".post-module-thumb > a > picture > source").attr("data-srcset");
                        // 发布日期
                        data.releaseDate = $(html).find("div.post-info > div.list-footer > span > time").attr("datetime");
                        // 获取当前时间
                        const currentDate = new Date();
                        // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                        data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
                        // 下载量
                        data.downloadVolume = $(html).find("div.post-info > div.post-list-meta-box > ul > li.post-list-meta-views > span").text();

                        // 访问内页
                        this.$message({message: `访问 ${data.originalLink}`, type: 'success'});
                        const res = await axios.post(data.originalLink.replace(/^https?:\/\/www\.nsxu\.com/, 'nsxu'));
                        const innerHtml = $(res.data);
                        // 分类
                        data.classification = innerHtml.find("#post-meta > div.post-meta-row > ul > li:nth-child(1) > div > a:nth-child(1)").text().trim();
                        // 简介
                        data.briefIntroduction = innerHtml.find("#primary-home > article > header > h1").text();
                        // 删除部分内容
                        innerHtml.find("#download-box").remove();
                        // 替换img
                        const $imgs = innerHtml.find("#primary-home > article > div.entry-content img");
                        // 遍历每个 img 标签
                        $imgs.each(function() {
                            // 检查 data-src 属性是否存在并且不为空
                            const dataSrc = $(this).attr('data-src');
                            if (dataSrc) {
                                // 如果存在 data-src 属性，则将 img 的 src 属性替换为 data-src 的值
                                $(this).attr('src', dataSrc);
                            }
                        });
                        // 内容
                        data.content = innerHtml.find("#primary-home > article > div.entry-content").html().trim();

                        // 将数据写入数据库
                        await this.addDataRetrieval(data);

                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 3000));
                    } catch (error) {
                        this.failedToCrawl.push(`${url} #${i}`); // 写入失败数组
                        this.$message.error(`${url} #${i} 爬取页码失败`);
                    }
                }
            }
            else if(this.key == 'mitaobo'){ // ##蜜桃源码网##

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "蜜桃源码网"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }

                    let url = row.url.replace("*", i);
                    // 蜜桃源码网 特殊跳转
                    if (i === 1) {
                        url = row.url.replace(/\/page\/.*$/, ''); // 去除后面 以防触发跳转
                    }
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);
                        // 循环
                        await Promise.all(html.find("div.row.posts-wrapper.scroll > div").map(async (index, item) => {
                            const data = {
                                source: "蜜桃源码网",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("mitaobo/", "https://www.mitaobo.com/")
                            };
                            // 储存在数据库 的 原链接
                            const originalLink = $(item).find("div.entry-media > div > a").attr("href");

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("header > h2 > a").text();
                            // 封面
                            data.cover = $(item).find("div.entry-media > div > a > img").attr("data-src");
                            // 发布日期
                            data.releaseDate = $(item).find("div.entry-footer > div > span.meta-date > time").text().trim();
                            // 下载量
                            data.downloadVolume = $(item).find("div.entry-footer > div > span.meta-views").text().trim();
                            // 分类
                            data.classification = $(item).find("div.entry-wrapper > span > a:nth-child(1)").text();
                            // 原链接
                            data.originalLink = originalLink;
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace(/^https?:\/\/www\.mitaobo\.com/, 'mitaobo'));
                            const innerHtml = $(res.data);
                            // 删除 无用内容
                            innerHtml.find(".entry-share").remove();
                            innerHtml.find(".entry-tags").remove();
                            innerHtml.find(".websitebox_wy").remove();
                            // 简介
                            data.briefIntroduction = innerHtml.find("div.entry-wrapper > div >h2").text();
                            // 内容
                            data.content = innerHtml.find("div.entry-wrapper").html().trim();
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                    }
                }
            }
            else if(this.key == 'jiufuquan'){ // ## 玖富圈 ##

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "玖富圈"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }

                    let url = row.url.replace("*", i);
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);

                        // 初始化 classification
                        let classification = "";
                        // 分类
                        if (url.includes("classid=65")) {
                            classification = "游戏源码";
                        } else if (url.includes("classid=6")) {
                            classification = "网站源码";
                        } else if (url.includes("classid=5")) {
                            classification = "棋牌源码";
                        } else if (url.includes("classid=49")) {
                            classification = "帝国CMS";
                        } else if (url.includes("classid=67")) {
                            classification = "织梦模板";
                        } else if (url.includes("classid=76")) {
                            classification = "Pbootcms";
                        } else if (url.includes("classid=77")) {
                            classification = "易优模板";
                        } else if (url.includes("classid=88")) {
                            classification = "其它模板";
                        } else if (url.includes("classid=34")) {
                            classification = "绿色软件";
                        }

                        // 循环
                        await Promise.all(html.find("#mainbox > div.new-post > article").map(async (index, item) => {
                            const data = {
                                source: "玖富圈",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("jiufuquan/", "https://www.jiufuquan.com/"),
                                classification: classification,
                            };
                            // 储存在数据库 的 原链接
                            const originalLink = $(item).find("div > h2 > a").attr("href");

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("div > h2 > a").text();
                            // 封面
                            data.cover = $(item).find("figure > a > img").attr("src");
                            // 发布日期
                            data.releaseDate = $(item).find("div > p.data.clearfix > time").attr("title");
                            // 下载量
                            data.downloadVolume = $(item).find("div > p.data.clearfix > span.view.fl").text();
                            // 分类

                            // 原链接
                            data.originalLink = originalLink;
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace(/^https?:\/\/www\.jiufuquan\.com/, 'jiufuquan'));
                            const innerHtml = $(res.data);
                            // 简介
                            const briefIntroduction = innerHtml.find("#mainbox > article > div.art-content > div.news_con").text();
                            // 判断字符串长度是否超过
                            if (briefIntroduction.length > 750) {
                                // 超字符添加省略号
                                data.briefIntroduction = briefIntroduction.substring(0, 750) + '...';
                            } else {
                                // 没有超过直接赋值
                                data.briefIntroduction = briefIntroduction;
                            }
                            // 内容
                            data.content = innerHtml.find("#mainbox > article > div.art-content > div.news_con").html();
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                        console.log(url,error);
                    }
                }
            }
            else if(this.key == 'yuanmawu'){ // ## 源码屋 #############

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "源码屋"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i === 1) {
                        continue; // 跳过1
                    }

                    let url = row.url.replace("*", i);
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);

                        // 循环
                        await Promise.all(html.find("#posts > div").map(async (index, item) => {
                            const data = {
                                source: "源码屋",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("yuanmawu/", "https://www.yuanmawu.net/"),
                            };
                            // 储存在数据库 的 原链接
                            const originalLink = $(item).find("h3 > a").attr("href");

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("h3 > a").text();
                            // 封面
                            data.cover = $(item).find("div.img > a > img").attr("data-src");
                            // 发布日期
                            data.releaseDate = $(item).find("div.grid-author > span").text();
                            // 下载量
                            data.downloadVolume = $(item).find("div.grid-meta > span.downs").text();
                            // 分类
                            data.classification = $(item).find("div.cat > a").text();
                            // 原链接
                            data.originalLink = originalLink;
                            // 简介
                            data.briefIntroduction = $(item).find("div.excerpt").text();
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace(/^https?:\/\/www\.yuanmawu\.net/, 'yuanmawu'));
                            const innerHtml = $(res.data);
                            // 删除 无用内容
                            innerHtml.find(".article-shares").remove();
                            innerHtml.find(".article-act").remove();
                            // 内容
                            data.content = innerHtml.find("div.content-wrap > div > article").html().trim();
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                        console.log(url,error);
                    }
                }
            }
            else if(this.key == '8yweb'){ // ## 八爷源码 #############

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "八爷源码"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i === 1) {
                        continue; // 跳过1
                    }

                    let url = row.url.replace("*", i);
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);

                        // 循环
                        await Promise.all(html.find(".posts-wrapper > div").map(async (index, item) => {
                            const data = {
                                source: "八爷源码",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("8yweb/", "https://www.8yweb.com/"),
                            };
                            // 获取原链接
                            const originalLink = $(item).find("h2 > a").attr("href").replace('https://localhost:8080', 'https://www.8yweb.com');

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("h2 a").text();
                            // 简介
                            data.briefIntroduction = data.title;
                            // 封面
                            data.cover = $(item).find(".placeholder > a > img").attr("data-src").replace('https://localhost:8080', 'https://www.8yweb.com');
                            // 发布日期
                            data.releaseDate = $(item).find("div.entry-footer > a:first-child").text();
                            // 下载量
                            data.downloadVolume = $(item).find("a.views").text();
                            // 原链接
                            data.originalLink = originalLink;
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace(/^https?:\/\/www\.8yweb\.com/, '8yweb'));
                            const innerHtml = $(res.data);
                            // 删除 无用内容
                            innerHtml.find(".lcp-article-files").remove();
                            innerHtml.find(".superseo-relevant").remove();
                            // 分类
                            data.classification = innerHtml.find(".container .article-meta span:nth-child(2) > a").text();
                            // 替换img
                            const $imgs = innerHtml.find(".ue-image");
                            // 遍历每个 img 标签
                            $imgs.each(function() {
                                // 检查 data-src 属性是否存在并且不为空
                                const dataSrc = $(this).attr('data-src');
                                if (dataSrc) {
                                    // 如果存在 data-src 属性，则将 img 的 src 属性替换为 data-src 的值
                                    $(this).attr('src', dataSrc);
                                }
                            });
                            // 内容
                            data.content = innerHtml.find(".lcp-nocopy").html().trim();
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                        console.log(url,error);
                    }
                }
            }
            else if(this.key == 'timibbs'){ // ## 天美社区 #############

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "天美社区"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    if (i == 0) {
                        continue; // 跳过0
                    }
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }

                    let url = row.url.replace("*", i);
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);

                        // 循环
                        await Promise.all(html.find("#ri_home_lastpost_widget-2 > section > div.posts-warp.row.row-cols-2.row-cols-md-3.row-cols-lg-4.row-cols-xl-5.g-2.g-lg-3 > div").map(async (index, item) => {
                            const data = {
                                source: "天美社区",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("timibbs/", "https://timibbs.net/"),
                            };
                            // 获取原链接
                            const originalLink = $(item).find("article > div.entry-media.ratio.ratio-4x3 > a").attr("href");

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("article > div.entry-media.ratio.ratio-4x3 > a").attr("title");
                            // 封面
                            data.cover = $(item).find("article > div.entry-media.ratio.ratio-4x3 > a").attr("data-bg");
                            // 下载量
                            data.downloadVolume = $(item).find("article > div.entry-wrapper > div.entry-meta > span.meta-views").text();
                            // 原链接
                            data.originalLink = originalLink;
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace('https://timibbs.net', 'timibbs'));
                            const innerHtml = $(res.data);
                            // 删除 无用内容
                            // innerHtml.find("#sc_error > a").remove();
                            // innerHtml.find("#sc_shuoming").remove();
                            // 发布日期
                            data.releaseDate = innerHtml.find("div.archive-hero.post-hero.shop-hero > div.container.py-3.py-md-4 > div > div.col-lg-9.info-box > div.article-header.mb-0.mt-3.mt-lg-0 > div > span.meta-date").text();
                            // 简介
                            data.briefIntroduction = innerHtml.find("#sc_shuoming").next('p').text();
                            // 分类
                            data.classification = innerHtml.find("div.archive-hero.post-hero.shop-hero > div.container.py-3.py-md-4 > div > div.col-lg-9.info-box > div.article-header.mb-0.mt-3.mt-lg-0 > div > span.meta-cat-dot > a:nth-child(2)").text();
                            // 内容
                            data.content = innerHtml.find("div.content-wrapper > div.card > article").html().trim();
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                        console.log(url,error);
                    }
                }
            }
            else if(this.key == 'wusuo8'){ // ## 吾索源码网 #############

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", {source: "吾索源码网"});
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    this.customPage = i
                    if (i == 0) {
                        continue; // 跳过0
                    }
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }

                    let url = row.url.replace("*", i);
                    try {
                        const response = await axios.get(url);
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 爬取成功，正在解析源码`),
                            duration: 2500,
                        });
                        const html = $(response.data);

                        // 循环
                        await Promise.all(html.find("div.info-list > ul > li").map(async (index, item) => {
                            const data = {
                                source: "吾索源码网",
                                pageCount: i,
                                retrievePageCountLinks: url.replace("wusuo8/", "https://www.wusuo8.com/"),
                            };
                            // 获取原链接
                            const originalLink = $(item).find("a").attr("href");

                            // 判断链接是否存在于数据库中
                            const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                            if (existsInDatabase) {
                                console.log(`${originalLink} 已存在数据库中`);
                                this.$notify({
                                    title: '跳过',
                                    message: h('i', {style: 'color: #2196f3;font-weight: 700;'}, `${originalLink} 已存在数据库中`),
                                });
                                return; // 如果已存在，则跳过当前数据
                            }
                            // 标题
                            data.title = $(item).find("div > h5 > a").attr("title");
                            // 封面
                            data.cover = $(item).find("a > img").attr("src");
                            // 分类
                            data.classification = html.find("div.breadcrumb > li:nth-child(2) > a").text();
                            // 原链接
                            data.originalLink = originalLink;
                            // 访问内页
                            const res = await axios.get(data.originalLink.replace('https://www.wusuo8.com', 'wusuo8'));
                            const innerHtml = $(res.data);
                            // 发布日期
                            data.releaseDate = innerHtml.find("div.show-nav > ul > li:nth-child(3)").text().replace('最后更新：','');
                            // 下载量
                            data.downloadVolume = 0

                            // 删除 无用内容
                            // 获取 article 元素下的所有 div
                            const divs = innerHtml.find("article div");
                            // 删除前三个和后三个 div
                            divs.slice(0, 3).remove(); // 删除前三个
                            divs.slice(-4).remove();    // 删除后三个
                            // 查找并删除 <article> 元素内的所有注释节点
                            innerHtml.find("article").contents().filter(function() {
                                return this.nodeType === 8; // 注释节点的 nodeType 为 8
                            }).remove();
                            // 查找并删除 <article> 元素内的所有注释节点
                            innerHtml.find("article").contents().filter(function() {
                                return this.nodeType === 8; // 注释节点的 nodeType 为 8
                            }).remove();
                            // 获取 <article> 元素的内容，但不包括已删除的元素
                            let content = innerHtml.find("article").clone(); // 克隆一份以确保不修改原始内容
                            content.find("div").remove(); // 删除所有 <div> 元素

                            // 内容
                            data.content = content.html().trim(); // 存储提取的 HTML 内容

                            // 提取简介文本
                            const briefIntroduction = content.text().trim();

                            // 判断简介文本长度是否超过750个字符
                            if (briefIntroduction.length > 750) {
                                // 超过750个字符，截取前750个字符作为简介，然后添加省略号
                                data.briefIntroduction = briefIntroduction.substring(0, 750) + '...';
                            } else {
                                // 没有超过750个字符，直接赋值给简介
                                data.briefIntroduction = briefIntroduction;
                            }
                            // 获取当前时间
                            const currentDate = new Date();
                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;


                            // 将数据写入数据库
                            await this.addDataRetrieval(data);
                        }));
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `${url} 解析完成`),
                            duration: 2000,
                        });
                        // 添加延迟
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                        console.log(url,error);
                    }
                }
            }else if (this.key == '515ymw') { // ## 515源码 #############

                // 请求数据库 获取已经趴取到的数据 对比
                const originalLinksResponse = await axios.post("admin/api/index/getAllOriginalLinks", { source: "515源码" });
                const originalLinks = originalLinksResponse.data.data

                for (let i = this.customPage; i <= row.totalPage; i++) {
                    console.log("开始迭代页面", i); // 添加日志，检查页面迭代是否正常

                    this.customPage = i
                    if (i == 0) {
                        continue; // 跳过0
                    }
                    // 检查中止标志
                    if (this.abortFlag) {
                        console.log('趴取已中止');
                        this.$message.warning('趴取已中止');
                        this.abortFlag = false; // 重置中止标志
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }
                    if (i > 9999) {
                        this.$message.warning('趴取已中止');
                        this.startCrawlingButton = false; // 解除趴取按钮禁用
                        return; // 中止趴取
                    }

                    let url = row.url.replace("*", i);
                    try {
                        await axios.get('admin/api/index/getWebPageSourceCode', { params: { url: row.url.replace("*", i) } })
                            .then(async res => {
                                this.$notify({
                                    title: '成功',
                                    message: h('i', { style: 'color: #8bc34a;font-weight: 700;' }, `${url} 爬取成功，正在解析源码`),
                                    duration: 2500,
                                });
                                const html = $(res.data.data); // 使用 jQuery 解析页面源代码
                                const elements = html.find(".col").toArray();
                                for (const [index, item] of elements.entries()) {
                                    console.log("循环", index);
                                    const data = {
                                        source: "515源码",
                                        pageCount: i,
                                        retrievePageCountLinks: url,
                                    };
                                    // 获取原链接
                                    const originalLink = $(item).find("h3 > a").attr("href");

                                    // 判断链接是否存在于数据库中
                                    const existsInDatabase = originalLinks.some(link => link.originalLink === originalLink);
                                    if (existsInDatabase) {
                                        console.log(`${originalLink} 已存在数据库中`);
                                        this.$notify({
                                            title: '跳过',
                                            message: h('i', { style: 'color: #2196f3;font-weight: 700;' }, `${originalLink} 已存在数据库中`),
                                        });
                                        continue; // 如果已存在，则跳过当前数据
                                    }
                                    // 标题
                                    data.title = $(item).find("h3 > a").attr("title");
                                    // 封面
                                    data.cover = "https://515ymw.cc/"+$(item).find("img").attr("data-src");
                                    // 分类
                                    data.classification = html.find("div.card-body > div.d-flex.flex-wrap.justify-content-between.align-items-start.pb-2 > div:nth-child(2) > a").attr("title");
                                    // 原链接
                                    data.originalLink = "https://515ymw.cc/"+originalLink;
                                    // 访问内页
                                    await axios.get('admin/api/index/getWebPageSourceCode', { params: { url: data.originalLink } })
                                        .then(async res => {
                                            const innerHtml = $(res.data.data);
                                            // 发布日期
                                            data.releaseDate = '';
                                            // 下载量
                                            data.downloadVolume = 0

                                            // 获取 元素的内容
                                            let content = innerHtml.find(".font-size-md")

                                            // 替换img
                                            const $imgs = innerHtml.find("img");
                                            // 遍历每个 img 标签
                                            $imgs.each(function() {
                                                // 检查
                                                const dataSrc = "https://515ymw.cc/"+$(this).attr('src');
                                                if (dataSrc) {
                                                    // 替换
                                                    $(this).attr('src', dataSrc);
                                                }
                                            });

                                            // 内容
                                            data.content = content.html().trim(); // 存储提取的 HTML 内容

                                            // 提取简介文本
                                            const briefIntroduction = content.text().trim();

                                            // 判断简介文本长度是否超过750个字符
                                            if (briefIntroduction.length > 750) {
                                                // 超过750个字符，截取前750个字符作为简介，然后添加省略号
                                                data.briefIntroduction = briefIntroduction.substring(0, 750) + '...';
                                            } else {
                                                // 没有超过750个字符，直接赋值给简介
                                                data.briefIntroduction = briefIntroduction;
                                            }
                                            // 获取当前时间
                                            const currentDate = new Date();
                                            // 格式化为指定格式：YYYY-MM-DD HH:mm:ss
                                            data.addTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

                                            // 将数据写入数据库
                                            await this.addDataRetrieval(data);
                                            console.log("将数据写入数据库", data);
                                        });
                                }

                                this.$notify({
                                    title: '成功',
                                    message: h('i', { style: 'color: #8bc34a;font-weight: 700;' }, `${url} 解析完成`),
                                    duration: 2000,
                                });
                                // 添加延迟
                                await new Promise(resolve => setTimeout(resolve, 1000));
                            });
                    } catch (error) {
                        this.failedToCrawl.push(url); // 写入失败数组
                        this.$message.error(`${url} 爬取失败`);
                        console.log(url, error);
                    }
                }
            }

            this.$notify({
                title: '趴取结束',
                message: h('i', {style: 'color: #8bc34a;'}, `趴取${row.totalPage}页，${row.totalNumber}条数据，结束`)
            });
            // 解除禁用按钮
            this.startCrawlingButton = false;
            this.loading = false; // 请求结束后设置加载状态为 false
        },
        // 传递新增数据给后台
        addDataRetrieval(data) {
            const h = this.$createElement;
            // 不采集免费资源
            if(data.classification.includes('免费') || data.title.includes('免费')){
                this.$notify({
                    title: '失败',
                    message: h('i', {style: 'color: #FF9800;font-weight: 700;'}, '不采集免费资源'),
                    duration: 1000,
                });
                return;
            }
            axios.post('admin/api/index/addDataRetrieval', data)
                .then(res => {
                    if (res.data.code == 1) {
                        this.$notify({
                            title: '成功',
                            message: h('i', {style: 'color: #8bc34a;font-weight: 700;'}, `已添加至数据库`),
                            duration: 500,
                        });
                    } else {
                        this.$notify({
                            title: '失败',
                            message: h('i', {style: 'color: #FF9800;font-weight: 700;'}, res.data.msg),
                            duration: 1000,
                        });
                    }
                }).catch(error => {
                console.error(error);
            });
            // 添加延迟
            return new Promise(resolve => setTimeout(resolve, 300));
        }

    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    mounted() {
    },
}
</script>

<style scoped>
</style>
