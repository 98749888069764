<template>
    <div id="app">
        <el-image
            :src="banner"
            fit="fill"
            class="navigationBarLogo"
        ></el-image>
        <navigation-bar></navigation-bar>
        <el-main class="main">
            <div class="menu">
                <el-menu
                    :default-active="activeIndex"
                    mode="horizontal"
                    @select="handleSelect"
                    background-color="#409eff"
                    text-color="#fff"
                    active-text-color="#ffeb3b">
                    <el-menu-item index="首页">首页</el-menu-item>
                    <el-submenu index="玖富圈" popper-class="menu1">
                        <template slot="title">玖富圈</template>
                        <el-menu-item index="玖富圈-all">全部</el-menu-item>
                        <el-menu-item index="玖富圈-网站源码">网站源码</el-menu-item>
                        <el-menu-item index="玖富圈-绿色软件">绿色软件</el-menu-item>
                        <el-menu-item index="玖富圈-棋牌源码">棋牌源码</el-menu-item>
                        <el-menu-item index="玖富圈-帝国CMS">帝国CMS</el-menu-item>
                        <el-menu-item index="玖富圈-织梦模板">织梦模板</el-menu-item>
                        <el-menu-item index="玖富圈-其它模板">其它模板</el-menu-item>
                        <el-menu-item index="玖富圈-易优模板">易优模板</el-menu-item>
                        <el-menu-item index="玖富圈-Pbootcms">Pbootcms</el-menu-item>
                    </el-submenu>
                    <el-submenu index="源码当先" popper-class="menu1">
                        <template slot="title">源码当先</template>
                        <el-menu-item default-active index="源码当先-all">全部</el-menu-item>
                        <el-menu-item index="源码当先-模板源码">模板源码</el-menu-item>
                        <el-menu-item index="源码当先-微信源码">微信源码</el-menu-item>
                        <el-menu-item index="源码当先-棋牌源码">棋牌源码</el-menu-item>
                        <el-menu-item index="源码当先-BC">BC</el-menu-item>
                        <el-menu-item index="源码当先-H5">H5</el-menu-item>
                        <el-menu-item index="源码当先-端游">端游</el-menu-item>
                        <el-menu-item index="源码当先-手游">手游</el-menu-item>
                        <el-menu-item index="源码当先-游戏源码">游戏源码</el-menu-item>
                        <el-menu-item index="源码当先-app源码">app源码</el-menu-item>
                        <el-menu-item index="源码当先-网站源码">网站源码</el-menu-item>
                        <el-menu-item index="源码当先-插件">插件</el-menu-item>
                    </el-submenu>
                    <el-submenu index="NSXU源码社" popper-class="menu1">
                        <template slot="title">NSXU源码社</template>
                        <el-menu-item index="NSXU源码社-all">全部</el-menu-item>
                        <el-menu-item index="NSXU源码社-其他源码">其他源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-支付源码">支付源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-小程序">小程序</el-menu-item>
                        <el-menu-item index="NSXU源码社-PHP源码">PHP源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-企业源码">企业源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-商城源码">商城源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-区块链源码">区块链源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-分销源码">分销源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-网站源码">网站源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-发卡源码">发卡源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-图片源码">图片源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-下载站源码">下载站源码</el-menu-item>
                        <el-menu-item index="NSXU源码社-电影源码">电影源码</el-menu-item>
                    </el-submenu>
                    <el-submenu index="蜜桃源码网" popper-class="menu1">
                        <template slot="title">蜜桃源码网</template>
                        <el-menu-item index="蜜桃源码网-all">全部</el-menu-item>
                        <el-menu-item index="蜜桃源码网-APP源码">APP源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-其他源码">其他源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-小程序">小程序</el-menu-item>
                        <el-menu-item index="蜜桃源码网-娱乐源码1">娱乐源码1</el-menu-item>
                        <el-menu-item index="蜜桃源码网-bc源码">bc源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-微信功能板块">微信功能板块</el-menu-item>
                        <el-menu-item index="蜜桃源码网-区块链">区块链</el-menu-item>
                        <el-menu-item index="蜜桃源码网-支付源码">支付源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-网站源码">网站源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-工具">工具</el-menu-item>
                        <el-menu-item index="蜜桃源码网-影视直播">影视直播</el-menu-item>
                        <el-menu-item index="蜜桃源码网-网站板块">网站板块</el-menu-item>
                        <el-menu-item index="蜜桃源码网-小说源码">小说源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-视频教程">视频教程</el-menu-item>
                        <el-menu-item index="蜜桃源码网-综合资讯">综合资讯</el-menu-item>
                        <el-menu-item index="蜜桃源码网-游戏源码">游戏源码</el-menu-item>
                        <el-menu-item index="蜜桃源码网-其他游戏">其他游戏</el-menu-item>
                    </el-submenu>
                    <el-submenu index="源码屋" popper-class="menu1">
                        <template slot="title">源码屋</template>
                        <el-menu-item index="源码屋-all">全部</el-menu-item>
                        <el-menu-item index="源码屋-功能源码">功能源码</el-menu-item>
                        <el-menu-item index="源码屋-网站源码">网站源码</el-menu-item>
                        <el-menu-item index="源码屋-软件工具">软件工具</el-menu-item>
                        <el-menu-item index="源码屋-游戏源码">游戏源码</el-menu-item>
                        <el-menu-item index="源码屋-区块链类">区块链类</el-menu-item>
                        <el-menu-item index="源码屋-影视小说">影视小说</el-menu-item>
                        <el-menu-item index="源码屋-视频直播">视频直播</el-menu-item>
                        <el-menu-item index="源码屋-支付平台">支付平台</el-menu-item>
                        <el-menu-item index="源码屋-网站模板">网站模板</el-menu-item>
                        <el-menu-item index="源码屋-理财互助">理财互助</el-menu-item>
                        <el-menu-item index="源码屋-红包扫雷">红包扫雷</el-menu-item>
                        <el-menu-item index="源码屋-金融源码">金融源码</el-menu-item>
                        <el-menu-item index="源码屋-热门资源">热门资源</el-menu-item>
                        <el-menu-item index="源码屋-股票期货">股票期货</el-menu-item>
                        <el-menu-item index="源码屋-社交源码">社交源码</el-menu-item>
                        <el-menu-item index="源码屋-商城源码">商城源码</el-menu-item>
                        <el-menu-item index="源码屋-源码代售">源码代售</el-menu-item>
                        <el-menu-item index="源码屋-双轨直销">双轨直销</el-menu-item>
                        <el-menu-item index="源码屋-模块插件">模块插件</el-menu-item>
                        <el-menu-item index="源码屋-小程序模板">小程序模板</el-menu-item>
                        <el-menu-item index="源码屋-电子商务">电子商务</el-menu-item>
                        <el-menu-item index="源码屋-主题模板">主题模板</el-menu-item>
                        <el-menu-item index="源码屋-IT技术学院">IT技术学院</el-menu-item>
                        <el-menu-item index="源码屋-站长资源">站长资源</el-menu-item>
                    </el-submenu>
                    <el-submenu index="八爷源码" popper-class="menu1">
                        <template slot="title">八爷源码</template>
                        <el-menu-item index="八爷源码-八爷源码-all">全部</el-menu-item>
                        <el-menu-item index="八爷源码-棋牌教程">棋牌教程</el-menu-item>
                        <el-menu-item index="八爷源码-区块链源码">区块链源码</el-menu-item>
                        <el-menu-item index="八爷源码-房卡约战类">房卡约战类</el-menu-item>
                        <el-menu-item index="八爷源码-金币电玩类">金币电玩类</el-menu-item>
                        <el-menu-item index="八爷源码-休闲娱乐">休闲娱乐</el-menu-item>
                        <el-menu-item index="八爷源码-金融理财">金融理财</el-menu-item>
                        <el-menu-item index="八爷源码-菠菜源码">菠菜源码</el-menu-item>
                        <el-menu-item index="八爷源码-客服通讯">客服通讯</el-menu-item>
                        <el-menu-item index="八爷源码-精品源码">精品源码</el-menu-item>
                        <el-menu-item index="八爷源码-打赏源码">打赏源码</el-menu-item>
                        <el-menu-item index="八爷源码-整站源码">整站源码</el-menu-item>
                        <el-menu-item index="八爷源码-真金竞技类">真金竞技类</el-menu-item>
                        <el-menu-item index="八爷源码-棋牌技术">棋牌技术</el-menu-item>
                        <el-menu-item index="八爷源码-棋牌素材">棋牌素材</el-menu-item>
                        <el-menu-item index="八爷源码-支付源码">支付源码</el-menu-item>
                        <el-menu-item index="八爷源码-H5棋牌源码">H5棋牌源码</el-menu-item>
                        <el-menu-item index="八爷源码-商城源码">商城源码</el-menu-item>
                        <el-menu-item index="八爷源码-微盘源码">微盘源码</el-menu-item>
                        <el-menu-item index="八爷源码-精品软件">精品软件</el-menu-item>
                        <el-menu-item index="八爷源码-常用工具">常用工具</el-menu-item>
                        <el-menu-item index="八爷源码-破解授权">破解授权</el-menu-item>
                        <el-menu-item index="八爷源码-分发源码">分发源码</el-menu-item>
                        <el-menu-item index="八爷源码-技术文章">技术文章</el-menu-item>
                        <el-menu-item index="八爷源码-模板插件">模板插件</el-menu-item>
                        <el-menu-item index="八爷源码-其它网站">其它网站</el-menu-item>
                        <el-menu-item index="八爷源码-技能培训">技能培训</el-menu-item>
                        <el-menu-item index="八爷源码-手游端游">手游端游</el-menu-item>
                        <el-menu-item index="八爷源码-棋牌网站">棋牌网站</el-menu-item>
                        <el-menu-item index="八爷源码-精品教程">精品教程</el-menu-item>
                        <el-menu-item index="八爷源码-商业服务">商业服务</el-menu-item>
                        <el-menu-item index="八爷源码-工具软件">工具软件</el-menu-item>
                        <el-menu-item index="八爷源码-新闻资讯">新闻资讯</el-menu-item>
                    </el-submenu>
                    <el-submenu index="天美社区" popper-class="menu1">
                        <template slot="title">天美社区</template>
                        <el-menu-item index="天美社区-all">全部</el-menu-item>
                        <el-menu-item index="天美社区-亲测专区">亲测专区</el-menu-item>
                        <el-menu-item index="天美社区-BC/QP">BC/QP</el-menu-item>
                        <el-menu-item index="天美社区-区块币圈">区块币圈</el-menu-item>
                        <el-menu-item index="天美社区-其他源码">其他源码</el-menu-item>
                        <el-menu-item index="天美社区-商业互换">商业互换</el-menu-item>
                        <el-menu-item index="天美社区-精品教程">精品教程</el-menu-item>
                        <el-menu-item index="天美社区-支付源码">支付源码</el-menu-item>
                        <el-menu-item index="天美社区-小程序">小程序</el-menu-item>
                        <el-menu-item index="天美社区-影视直播">影视直播</el-menu-item>
                        <el-menu-item index="天美社区-商城源码">商城源码</el-menu-item>
                        <el-menu-item index="天美社区-手游端游">手游端游</el-menu-item>
                        <el-menu-item index="天美社区-博客主题">博客主题</el-menu-item>
                        <el-menu-item index="天美社区-程序源码">程序源码</el-menu-item>
                        <el-menu-item index="天美社区-工具文档">工具文档</el-menu-item>
                        <el-menu-item index="天美社区-主题模板">主题模板</el-menu-item>
                        <el-menu-item index="天美社区-网站模板">网站模板</el-menu-item>
                        <el-menu-item index="天美社区-企业主题">企业主题</el-menu-item>
                    </el-submenu>
                    <el-submenu index="吾索源码网" popper-class="menu1">
                        <template slot="title">吾索源码网</template>
                        <el-menu-item index="吾索源码网-all">全部</el-menu-item>
                        <el-menu-item index="吾索源码网-网站源码">网站源码</el-menu-item>
                        <el-menu-item index="吾索源码网-APP源码">APP源码</el-menu-item>
                        <el-menu-item index="吾索源码网-网站插件">网站插件</el-menu-item>
                        <el-menu-item index="吾索源码网-网站模板">网站模板</el-menu-item>
                        <el-menu-item index="吾索源码网-游戏源码">游戏源码</el-menu-item>
                        <el-menu-item index="吾索源码网-工具/视频">工具/视频</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="vip"><i class="itemIcon el-icon-s-opportunity"></i>开通VIP</el-menu-item>
                    <el-menu-item index="order"><i class="itemIcon el-icon-tickets"></i>订单</el-menu-item>
<!--                    <el-menu-item index="service">-->
<!--                        <el-link-->
<!--                            type="primary"-->
<!--                            :underline="false"-->
<!--                            :href="service.webCustomerService"-->
<!--                            target="_blank"><i class="itemIcon el-icon-headset"></i>联系客服-->
<!--                        </el-link>-->
<!--                    </el-menu-item>-->
<!--                    <el-menu-item index="about"><i class="itemIcon el-icon-attract"></i>趴取网站</el-menu-item>-->
                </el-menu>
            </div>
            <div style="margin: 40px 0 15px 15px">
                <el-breadcrumb class="app-breadcrumb" separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item v-for="(item,index) in levelList" :key="index">{{ item }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card>
                <keep-alive>
                    <router-view/>
                </keep-alive>
            </el-card>
        </el-main>
    </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import axios from "axios";


export default {
    name: 'App',
    components: {
        NavigationBar
    },
    props: {},
    data() {
        // 这里存放数据
        return {
            activeIndex: '首页', // 激活的菜单
            levelList: [], // 面包屑
            banner:require('@/assets/nn.gif'), // 横幅
            // service: {webCustomerService: '', afterSalesCustomerService: ''}, // 客服
        }
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {
        '$route'() {
            this.updateBreadcrumb();
        }
    },
    // 方法集合
    methods: {
        // 更新面包屑
        updateBreadcrumb() {
            this.levelList.splice(0); // 清空面包屑列表
            // 获取路由参数
            const {source, classification, searchKey, id} = this.$route.query;
            if (source) {
                this.levelList.push(source); // 写入
                if (classification) {
                    this.levelList.push(classification.replace("all", '全部')); // 写入
                }
            }
            if (searchKey) {
                this.levelList.push(`搜索 ${searchKey}`); // 写入
            }
            // 检查路由是否为about，如果是，则添加 "趴取网站" 到面包屑列表中
            if (this.$route.path === '/about') {
                this.levelList.push("趴取网站"); // 写入
            }
            if (this.$route.path === '/vip') {
                this.levelList.push("会员VIP介绍"); // 写入
            }
            // 检查路由是否为详情
            if (this.$route.path === '/details') {
                this.levelList.push(`内容详情 #${id}`); // 写入
            }
            if (this.$route.path === '/login') {
                this.levelList.push("用户登录"); // 写入
            }
            if (this.$route.path === '/register') {
                this.levelList.push("用户注册"); // 写入
            }
            if (this.$route.path === '/order') {
                this.levelList.push("订单"); // 写入
            }
            // 如果 面包屑长度为空
            if (this.levelList.length === 0) {
                this.levelList.push("全部内容"); // 写入
            }
            console.log("levelList", this.levelList);
        },
        // 点击菜单触发回调
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            if (keyPath[0] == 'about') {
                this.$router.push({path: 'about'})
            } else if (keyPath[0] == '首页') {
                this.$router.push({path: '/'})
            } else if (keyPath[0] == '源码当先') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == 'NSXU源码社') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == '蜜桃源码网') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == '玖富圈') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == '源码屋') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == '八爷源码') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == '天美社区') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == '吾索源码网') {
                this.$router.push({
                    path: '/',
                    query: {source: keyPath[0], classification: keyPath[1].replace(keyPath[0] + '-', '')}
                })
            } else if (keyPath[0] == 'vip') {
                this.$router.push({path: '/vip'})
            } else if (keyPath[0] == 'order') {
                this.$router.push({path: '/order'})
            }
        },
        // 更新用户信息
        updateUserInformation() {
            // 用户信息
            const userinfo = JSON.parse(localStorage.getItem('userinfo'));
            if (userinfo) {
                axios.post('admin/api/user/updateUserInformation.html', '', {headers: {'token': userinfo.token}})
                    .then(res => {
                        this.userinfo = res.data.data.userinfo;
                        // 存储用户信息
                        localStorage.setItem('userinfo', JSON.stringify(this.userinfo));
                    });
            }
        },
        // 获取logo信息
        getLogo(){
            axios.post('admin/api/index/getLogo.html')
                .then(res => {
                    this.banner = res.data.data.banner;
                    console.log("banner", res.data.data.banner);
                });
            // 统计
            const userinfo = JSON.parse(localStorage.getItem('userinfo'));
            axios.post('admin/api/index/index.html', '', {headers: {'token': userinfo.token}})
                .then(res => {
                    this.a = res.data.data;
                });
        },
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        // 更新面包屑
        this.updateBreadcrumb();
        // 更新用户信息
        this.updateUserInformation();
        this.getLogo() // 获取logo信息
        // 获取客服信息
        // axios.post('admin/api/index/getService.html')
        //     .then(res => {
        //         this.service = res.data.data;
        //     });
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
    },
    beforeCreate() {
    },
    beforeMount() {
    }, // 生命周期 - 挂载之前
    beforeUpdate() {
    }, // 生命周期 - 更新之前
    updated() {
    }, // 生命周期 - 更新之后
    beforeDestroy() {
    }, // 生命周期 - 销毁之前
    destroyed() {
    }, // 生命周期 - 销毁完成
    activated() {
    } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 1200px;
    margin: auto;
}

.main {
    .menu {
        height: 40px;
    }
}

.dataTable {
    //border: 1px solid #DDD;
    //margin-bottom: 5px;
}

.el-menu-item .itemIcon {
    color: #f5f7fa;
    margin-right: 5px;
}

.menu1 {
    height: 380px;
    overflow: auto;
}

.menu1::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px; /* 滚动条整体圆角 */
}

.menu1::-webkit-scrollbar-track {
    background: rgba(64, 158, 255, 0.66); /* 滚动条轨道背景色 */
}

.menu1::-webkit-scrollbar-thumb {
    background: #888; /* 滚动条滑块背景色 */
    border-radius: 5px; /* 滚动条滑块圆角 */
}

.menu1::-webkit-scrollbar-thumb:hover {
    background: #555; /* 鼠标悬停在滑块上时的背景色 */
}

</style>
<style scoped>
/deep/ .el-menu--horizontal {
    display: flex;
    justify-content: space-around;
}

>>> .el-main {
    padding-top: 15px !important;
}

>>> .el-submenu__title {
    padding: 0 16px;
}

#app >>> .el-menu--horizontal .el-menu--popup {
    display: flex !important;
}
</style>