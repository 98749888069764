<template>
    <div class="home">
        <el-table
            class="dataTable"
            v-loading="loading"
            :row-key="row => row.id"
            :data="tableData.filter(data => !search || data.title.toLowerCase().includes(search.toLowerCase()))">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-card style="width: 80%;margin: auto">
                    <el-descriptions title="快捷查看">
                        <el-descriptions-item label="来源">{{props.row.source}}</el-descriptions-item>
                        <el-descriptions-item label="ID">{{props.row.id}}</el-descriptions-item>
                        <el-descriptions-item label="简介">{{props.row.briefIntroduction}}</el-descriptions-item>
                        <el-descriptions-item label="分类">
                            <el-tag size="small">{{props.row.classification}}</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item label="下载量">{{props.row.downloadVolume}}</el-descriptions-item>
                        <el-descriptions-item label=" 预览图">
                            <el-image
                                style="width: 100px; height: 100px"
                                :src="props.row.cover"
                                :preview-src-list="[props.row.cover]">
                            </el-image></el-descriptions-item>
                    </el-descriptions>
                    </el-card>
                </template>
            </el-table-column>
            <el-table-column
                prop="title"
                label="源码标题">
            </el-table-column>
            <el-table-column
                prop="source"
                label="原站名称"
                width="100%">
            </el-table-column>
            <el-table-column
                prop="originalLink"
                label="原站地址">
                <template slot-scope="scope">
                    <el-link :underline="false" target="_blank" @click="copyContent(scope.row.originalLink)">{{scope.row.originalLink}}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                prop="price"
                label="售价"
                width="100%">
                <template slot-scope="scope">
                    <div v-if="scope.row.price">
                        <el-tag size="small" effect="plain" type="success">{{ scope.row.price }} USDT</el-tag>
                    </div>
                    <div v-else>
                        <el-tag size="mini" effect="plain" type="info">联系客服询价</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="addTime"
                label="发布日期"
                sortable
                width="100%">
                <template slot-scope="scope">
                    <!-- 使用过滤器来格式化日期 -->
                    <span>{{ scope.row.addTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="250">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        :to="{path:'/details', params:{id:scope.row.id}}"
                        @click="toDetails(scope.row)"
                    >详情</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        @click.native.prevent="download(scope.$index, scope.row, '')"
                    >下载</el-button>
                    <el-button
                        size="mini"
                        type="warning"
                        @click.native.prevent="download(scope.$index, scope.row, 'vip')"
                        v-if="isVIPExpired()"
                    >VIP下载</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div style="margin: 40px 0 10px 0">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[30, 50, 100, 200]"
                :page-size="sizePage"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>

        <!-- 付款界面 -->
        <el-dialog title="支付" :visible.sync="dialogPayVisible" top="6vh">
            <img :src="payInfo.payImg" alt="" class="payImg">
            <el-descriptions title="源码信息" class="downloadInfo">
                <el-descriptions-item label="源码ID">{{downloadInfo.id}}</el-descriptions-item>
                <el-descriptions-item label="用户">
                    <div v-if="userinfo">
                        {{userinfo.email}}
                    </div>
                    <div v-else>
                        未登录
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="价格">
                    <div v-if="downloadInfo.price">
                        <el-tag size="small" effect="plain" type="success">{{ downloadInfo.price }} USDT</el-tag>
                    </div>
                    <div v-else>
                        <el-tag size="mini" effect="plain" type="info">联系客服询价</el-tag>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="标题">{{downloadInfo.title}}</el-descriptions-item>
            </el-descriptions>
            <div class="payText">{{payInfo.payText}}</div>
            <span slot="footer" class="dialog-footer"><el-button round @click="drawer = true">上传支付凭证</el-button></span>
        </el-dialog>

        <!-- 上传凭证 -->
        <el-drawer
            :title="'上传购买 #'+buyItem.id+'凭证'"
            :visible.sync="drawer"
            size="40%"
            :before-close="handleClose">
            <div class="uploadBox">
                <div v-if="upload" class="upload">
                    <el-upload
                        class="upload-demo"
                        drag
                        action="admin/api/common/upload"
                        :headers="headers"
                        :on-error="upError"
                        :on-success="upSuccess"
                        multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将凭证图片拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传图片文件，且不超过2Mb</div>
                    </el-upload>
                </div>
                <div v-if="!upload" class="uploadImg">
                    <el-image
                        class="img"
                        :src="voucherImage.fullurl"
                        :preview-src-list="[voucherImage.fullurl]">
                    </el-image>
                    <span>{{voucherImage.fullurl}}</span>
                </div>
                <el-form v-loading="loading">
                    <el-input type="textarea" v-model="voucherRemarks" :rows="3" placeholder="如您需对此凭证进行备注，请在此处输入备注。备注非必填"></el-input>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitVouchers" :loading="loading" :disabled="loading">{{ loading ? '提交中 ...' : '提交凭证进行审核' }}</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>


<script>
import axios from "axios";

export default {
    name: 'home',
    components: {},
    props: {},
    data() {
        // 这里存放数据
        return {
            loading: false, // 表格加载状态
            currentPage: 1, // 当前页数
            sizePage: 30, // 每页条数
            total: 0, // 共有多少条
            tableData: [],  // 数据表格内容
            search: '', // 搜索
            source:'all', // 来源
            classification:'all', // 分类
            searchKey:'', // 搜索
            dialogPayVisible:false, // 显示隐藏 支付界面
            downloadInfo:'', // 下载ID
            payInfo:{payImg:'',payText:''}, // 支付信息
            voucherRemarks:'', // 凭证备注
            drawer: false, // 上传凭证界面 显示隐藏
            voucherImage:{fullurl:'',url:''}, // 凭证图片
            buyItem:{}, // 购买信息
            upload:true, // 是否隐藏上传
            headers:{token:''}, // 标头
        }
    },
    // 计算属性 类似于 data 概念
    computed: {
    },
    // 监控 $route 对象的变化
    watch: {
        '$route'(to) {
            // 获取新的路由参数
            const { source, classification, searchKey } = to.query;
            if (source) {
                // 如果有传递来源参数，将其存储在组件的 data 中并调用 requestData() 方法
                this.source = source;
            }else{
                // 没传递过来，设置为'all'
                this.source = 'all';
            }
            if (classification) {
                // 如果有传递分类参数，将其存储在组件的 data 中并调用 requestData() 方法
                this.classification = classification;
            }else{
                this.classification = 'all';
            }
            if (searchKey) {
                // 如果有传递分类参数，将其存储在组件的 data 中并调用 requestData() 方法
                this.searchKey = searchKey;
            }else{
                this.searchKey = '';
            }
            this.requestData();
        }
    },
    // 方法集合
    methods: {
        // 计算vip到期时间
        isVIPExpired() {
            if (this.userinfo && this.userinfo.viptime) {
                const vipExpirationDate = new Date(this.userinfo.viptime);
                const currentDate = new Date();
                return vipExpirationDate > currentDate;
            }
            return false;
        },
        // 提交凭证
        submitVouchers(){
            if (this.loading) {
                return;
            }
            if (!this.voucherImage.url.trim()) {
                this.$message.warning("凭证不能为空");
                return
            }
            this.$confirm('确认无误提交您的凭证?', '提示', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true; // 按钮加载
                // 提交后台
                axios.post('admin/api/index/addOrder.html', {
                    voucher: this.voucherImage.url, // 凭证
                    voucherRemarks: this.voucherRemarks, // 凭证备注
                    type: "购买源码", // 类型
                    name: this.buyItem.title, // 名称
                    productID: this.buyItem.id // 产品id
                }, { headers: { 'token': this.userinfo.token } })
                    .then(res => {
                        console.log("res",res);
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    // 初始化
                    this.drawer = false; // 上传凭证界面 显示隐藏
                    this.voucherImage = {fullurl:'',url:''}; // 凭证图片
                    this.upload=true; // 是否隐藏上传
                    this.voucherRemarks=''; // 凭证备注
                    this.loading=false; // 提交凭证 切换成 加载
                });
            })
        },
        // 上传失败触发
        upError(err, file) {
            console.log('上传失败', JSON.parse(err.message))
            if (file.status == 'fail') {
                this.$message.error(JSON.parse(err.message).msg)
            }
        },
        // 上传成功触发
        upSuccess(res, file) {
            console.log('上传成功', res)
            console.log('file.status', file.status)
            if (file.status == 'success') {
                this.$message.success(res.msg)
                this.voucherImage = res.data
                this.upload = false
            }
        },
        // 关闭上传凭证前的回调
        handleClose(done) {
            this.$confirm('还有未上传的凭证哦 确定关闭吗？')
                .then(res => { // 确定关闭
                    done();
                    console.log("确定关闭",res);
                    // 初始化
                    this.voucherImage = {fullurl:'',url:''}; // 凭证图片
                    this.upload=true; // 是否隐藏上传
                    this.voucherRemarks=''; // 凭证备注
                    this.loading=false; // 提交凭证 切换成 加载
                }).catch(res => { // 取消关闭
                console.log("取消关闭",res);
            });
        },
        // 复制
        copyContent(content) {
            // 创建DOM元素
            let domInput = document.createElement("input");
            // 将要复制的内容传递给DOM
            domInput.value = content;
            // 为DOM声明ID
            domInput.id = "creatDom";
            // 向body添加DOM
            document.body.appendChild(domInput);
            // 选择对象
            domInput.select();
            // 执行浏览器复制命令
            document.execCommand("Copy");
            // 获取DOM
            let creatDom = document.getElementById("creatDom");
            // 删除DOM
            creatDom.parentNode.removeChild(creatDom);
            this.$message.success("复制成功");
        },
        // 切换 显示数量
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.requestData(this.currentPage,val)
        },
        // 切换 翻页
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.requestData(val,this.sizePage)
        },
        // 点击下载操作
        download(index, row, vip) {
            if(!this.userinfo){
                this.$message.warning("请先登录账号");
                return
            }
            console.log(index, row);
            this.downloadInfo = row; // 下载id
            this.headers = {token:this.userinfo.token}; // 配置标头
            this.buyItem = row
            // vip下载
            if(vip == 'vip'){
                if(this.userinfo.dailyFrequency - this.orderTodayCount <= 0){
                    this.$message.warning("今日次数已用尽");
                    return;
                }
                this.$confirm(`确定使用VIP下载次数，购买价值 $${row.price} 的源码吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 用户确定下载，提交后台
                    axios.post('admin/api/index/addOrder.html', {
                        voucher: 'vip', // 凭证
                        voucherRemarks: this.voucherRemarks, // 凭证备注
                        type: "购买源码", // 类型
                        name: this.buyItem.title, // 名称
                        productID: this.buyItem.id // 产品id
                    }, { headers: { 'token': this.userinfo.token } })
                        .then(res => {
                            console.log("res",res);
                            if (res.data.code == 1) {
                                this.$message.success(res.data.msg);
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        }).catch(error => {
                        console.error(error);
                    }).finally(() => {
                        // 初始化
                        this.drawer = false; // 上传凭证界面 显示隐藏
                        this.voucherImage = {fullurl:'',url:''}; // 凭证图片
                        this.upload=true; // 是否隐藏上传
                        this.voucherRemarks=''; // 凭证备注
                        this.loading=false; // 提交凭证 切换成 加载
                    });
                })
                return
            }
            this.dialogPayVisible = true // 显示支付界面
        },
        // 请求数据
        requestData(curren = this.currentPage, size = this.sizePage, source = this.source, classification = this.classification, searchKey = this.searchKey) {
            this.loading = true; // 表格开始加载
            axios.post('admin/api/index/searchAllWebsites', { curren, size, source, classification, searchKey })
                .then(res => {
                    if (res.data.code == 1) {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    } else {
                        this.$message.error("数据请求失败！");
                    }
                }).catch(error => {
                console.error(error);
            }).finally(() => {
                this.loading = false; // 表格结束加载
            });
        },
        // 跳转详情页
        toDetails(row){
            console.log(row)
            this.$router.push({path:'details', query:{ id:row.id }})
        },
        // 查询今日使用VIP权限下载过多少次
        orderToday() {
            this.orderTodayCount =localStorage.getItem('orderTodayCount')
        },
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        // 用户信息
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
        // 获取路由参数
        const { source } = this.$route.query;
        // 如果有传递来源参数，将其存储在组件的 data 中
        if (source) {
            this.source = source;
        }
        // 调用 requestData() 方法以更新数据表格内容
        this.requestData();
        // 获取支付信息
        axios.post('admin/api/index/getPayInfo.html')
            .then(res => {
                this.payInfo = res.data.data;
            });
        this.orderToday() // 查询今日使用VIP权限下载过多少次
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
    },
    beforeCreate() {
    },
    beforeMount() {
    }, // 生命周期 - 挂载之前
    beforeUpdate() {
    }, // 生命周期 - 更新之前
    updated() {
    }, // 生命周期 - 更新之后
    beforeDestroy() {
    }, // 生命周期 - 销毁之前
    destroyed() {
    }, // 生命周期 - 销毁完成
    activated() {
    } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>

<style scoped>
.payImg{
    display: inline-block;
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
}
.downloadInfo{
    width: 70%;
    margin: auto auto 10px;
}
.payText{
    font-size: 20px;
    font-weight: bold;
}

/deep/ .el-drawer__container{
    text-align: left;
}
.uploadBox{
    margin: 10px 30px;
    .upload{
        margin: auto auto 20px;
    }
    .dialog-footer{
        margin-top: 20px;
    }
    .uploadImg{
        text-align: center;
        .img{
            width: 250px;
        }
        span{
            font-size: 12px;
            color: #666666;
            word-wrap: break-word; /* 当单词过长时自动换行 */
            margin: 10px 5px;
            display: block;
            text-align: left;
        }
    }
}
</style>