<template>
    <div id="order">
        <div class="operation">
            <el-row :gutter="20">
                <el-col :span="5" class="type">
                    <el-radio-group v-model="type" style="margin-bottom: 30px;" size="small" @input="operationType">
                        <el-radio-button label="all">全部</el-radio-button>
                        <el-radio-button label="购买源码">购买源码</el-radio-button>
                        <el-radio-button label="开通VIP">开通VIP</el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="7" class="state">
                    <el-radio-group v-model="state" style="margin-bottom: 30px;" size="small" @input="operationState">
                        <el-radio-button label="all">全部</el-radio-button>
                        <el-radio-button label="0">待审核</el-radio-button>
                        <el-radio-button label="1">通过审核</el-radio-button>
                        <el-radio-button label="2">审核失败</el-radio-button>
                    </el-radio-group>
                </el-col>
            </el-row>
        </div>
        <div style="padding: 15px">
            <template>
                <el-table
                    :data="tableData"
                    border
                    v-loading="loading"
                    :row-key="row => row.id"
                    style="width: 100%"
                    :row-class-name="tableRowClassName">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-card style="width: 80%;margin: auto">
                                <el-descriptions title="详细信息" :column="1">
                                    <el-descriptions-item label="名称">
                                        <el-tag style="cursor:pointer" size="small" @click="toDetails(props.row)">{{props.row.name}}</el-tag>
                                    </el-descriptions-item>

                                    <el-descriptions-item label="备注" v-if="props.row.voucherRemarks">
                                        {{props.row.voucherRemarks}}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="备注" v-else>
                                        <el-tag type="info" size="mini" effect="plain">无</el-tag>
                                    </el-descriptions-item>

                                    <el-descriptions-item label="回复" v-if="props.row.reply">
                                        {{props.row.reply}}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="回复" v-else>
                                        <el-tag type="info" size="mini" effect="plain">无</el-tag>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-card>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="类型"
                        width="100">
                    </el-table-column>
                    <el-table-column prop="name" label="名称" width="180">
                        <template slot-scope="scope">
                            <div v-if="scope.row.name && scope.row.name.length > 30">
                                <el-popover
                                    placement="right"
                                    width="400"
                                    :open-delay="400"
                                    trigger="hover">
                                    <span>{{ scope.row.name }}</span>
                                    <span slot="reference"
                                          class="voucherRemarks">{{ scope.row.name.substr(0, 30) + '...' }}</span>
                                </el-popover>
                            </div>
                            <div v-else-if="scope.row.name && scope.row.name.length <= 30">
                                {{ scope.row.name }}
                            </div>
                            <el-tag v-else type="info" size="mini" effect="plain">无</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="price"
                        label="价格"
                        width="100">
                    </el-table-column>
                    <el-table-column
                        prop="voucher"
                        label="凭证&备注"
                        width="90"
                        align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.voucher">
                                <el-popover
                                    placement="right"
                                    width="300"
                                    :open-delay="400"
                                    trigger="hover"
                                    v-if="scope.row.voucherRemarks">
                                    <span class="voucherRemarks">{{ scope.row.voucherRemarks }}</span>
                                    <el-image
                                        class="voucher"
                                        slot="reference"
                                        :src="'admin/'+scope.row.voucher"
                                        :preview-src-list="['admin/'+scope.row.voucher]">
                                    </el-image>
                                </el-popover>
                                <el-image
                                    v-else
                                    style="width: 50px"
                                    slot="reference"
                                    :src="'admin/'+scope.row.voucher"
                                    :preview-src-list="['admin/'+scope.row.voucher]">
                                </el-image>
                            </div>
                            <div v-else><el-tag type="warning" size="mini" effect="plain">VIP</el-tag></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="reply"
                        label="回复"
                        width="240">
                        <template slot-scope="scope">
                            <div v-if="scope.row.reply">
                                <el-popover
                                    placement="right"
                                    width="400"
                                    :open-delay="400"
                                    trigger="hover">
                                    <span>{{ scope.row.reply }}</span>
                                    <span slot="reference"
                                          class="voucherRemarks">{{ scope.row.reply}}</span>
                                </el-popover>
                            </div>
                            <el-tag v-else type="info" size="mini" effect="plain">无</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="customerService"
                        label="专属客服"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <el-link
                                type="primary"
                                :href="service.afterSalesCustomerService"
                                v-if="scope.row.customerService == '1'"
                                target="_blank">联系客服</el-link>
                            <el-tag type="info" size="mini" effect="plain" v-else>无</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="addTime"
                        label="提交时间"
                        sortable>
                    </el-table-column>
                    <el-table-column
                        prop="state"
                        label="审核状态"
                        width="90">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == 0">
                                <el-tag type="info" size="small" effect="dark">待审核</el-tag>
                            </div>
                            <div v-if="scope.row.state == 1">
                                <el-tag type="success" size="small" effect="dark">审核通过</el-tag>
                            </div>
                            <div v-if="scope.row.state == 2">
                                <el-tag type="danger" size="small" effect="dark">失败</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <div style="margin: 40px 0 10px 0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 30, 50, 100, 200]"
                    :page-size="sizePage"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<style>
.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
</style>
<script>
// 这里放置你的导入项
import axios from "axios";

export default {
    // 注册组件
    components: {},
    // 定义 props
    props: {},
    // 数据
    data() {
        return {
            userinfo: {}, // 用户信息
            loading: false, // 表格加载状态
            currentPage: 1, // 当前页数
            sizePage: 10, // 每页条数
            total: 0, // 共有多少条
            tableData: [],  // 数据表格内容
            search: '', // 搜索
            type: 'all', // 类型
            state: 'all', // 状态
            searchKey: '', // 搜索
            service:{webCustomerService:'',afterSalesCustomerService:''}, // 客服
        }
    },
    // 计算属性
    computed: {},
    // 监听数据变化
    watch: {},
    // 方法
    methods: {
        // type变化
        operationType(val){
            this.type = val;
            this.requestData(); // 重新请求数据
        },
        // state变化
        operationState(val){
            this.state = val;
            this.requestData(); // 重新请求数据
        },
        // 跳转详情页
        toDetails(row){
            if(row.type == '购买源码'){
                this.$router.push({ path:'details', query:{ id:row.productID }})
            }else if(row.type == '开通VIP'){
                this.$router.push({ path: '/vip' })
            }
        },
        // 表格分配颜色
        tableRowClassName({row}) {
            if (row.state == 1) {
                return 'success-row';
            } else if (row.state == 2) {
                return 'warning-row';
            }
            return '';
        },
        // 切换 显示数量
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.requestData(this.currentPage, val)
        },
        // 切换 翻页
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.requestData(val, this.sizePage)
        },
        // 请求数据
        requestData(curren = this.currentPage, size = this.sizePage, type = this.type, state = this.state, searchKey = this.searchKey) {
            this.loading = true; // 表格开始加载
            axios.post('admin/api/index/allOrders', {
                curren,
                size,
                type,
                state,
                searchKey
            }, {headers: {'token': this.userinfo.token}})
                .then(res => {
                    if (res.data.code == 1) {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    } else {
                        this.$message.error("数据请求失败！");
                    }
                }).catch(error => {
                console.error(error);
            }).finally(() => {
                this.loading = false; // 表格结束加载
            });
        },
    },
    // 生命周期钩子
    created() {
        // 组件创建后调用
        // 用户信息
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
        // 获取客服信息
        axios.post('admin/api/index/getService.html')
            .then(res => {
                this.service = res.data.data;
            });
    },
    mounted() {
        // 组件挂载后调用
    },
    beforeCreate() {
        // 组件创建前调用
    },
    beforeMount() {
        // 组件挂载前调用
    },
    beforeUpdate() {
        // 组件更新前调用
    },
    updated() {
        // 组件更新后调用
    },
    beforeDestroy() {
        // 组件销毁前调用
    },
    destroyed() {
        // 组件销毁后调用
    },
    activated() {
        // keep-alive 组件被激活时调用
        //如果数据为null
        if (!this.userinfo) {
            this.$message.warning("请先登录");
            this.$router.push({path: '/login'});
        }
        // 调用 requestData() 方法以更新数据表格内容
        this.requestData();
    },
    deactivated() {
        // keep-alive 组件被停用时调用
    }
}
</script>

<style scoped>
.voucherRemarks {
    font-size: 13px;
    color: #2c3e50;
    word-wrap: break-word;
    margin: 10px 5px;
    display: block;
    text-align: left;
    line-height: 18px;
}


@keyframes rainbow {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

.voucher {
    width: 50px;
    padding: 2px; /* 设置内边框来模拟边框 */
    animation: rainbow 5s linear infinite; /* 设置动画，5秒钟循环一次 */
    background: linear-gradient(60deg, #5f86f2, #a65ff2, #f25fd0, #f25f61, #f2cb5f, #abf25f, #5ff281, #5ff2f0);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

/deep/ .el-table .el-table__cell {
    padding: 5px 0;
}
/deep/ .el-tabs__header{
    margin: 0;
}
/deep/ .el-tabs--border-card>.el-tabs__content{
    padding: 0;
}

.operation{
    text-align: left;
    padding-left: 20px;
}
.type{
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: #3b9cc9;
    }
}
.state{
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: #ba5d31;
    }
}
</style>
