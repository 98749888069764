<template>
    <div class="details">
        <!-- 页面标题 -->
        <el-page-header @back="goBack" title="源码详情" :content="data.title"></el-page-header>
        <el-divider></el-divider>
        <!-- 简要介绍 -->
        <el-card shadow="hover" class="briefIntroduction">{{ data.briefIntroduction }}</el-card>

        <!-- 内容区域，使用 v-html 渲染 HTML 内容 -->
        <div class="content" v-html="data.content" @click="openImage"></div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    components: {},
    props: {},
    data() {
        return {
            id: this.$route.query.id,
            data: '',
        }
    },
    methods: {
        // 返回上一页
        goBack() {
            this.$router.back();
        },
        // 请求详情数据
        details() {
            axios.post('admin/api/index/details', {id: this.id})
                .then(res => {
                    if (res.data.code == 1) {
                        this.data = res.data.data;
                    } else {
                        this.$message.error("数据请求失败！");
                    }
                }).catch(error => {
                console.error(error);
            });
        },
        // 打开图片
        openImage(event) {
            // 判断点击的是否是图片
            if (event.target.tagName === 'IMG') {
                // 创建一个新的窗口显示图片
                // window.open(event.target.src, '_blank');
            }
        }
    },
    created() {
        // 页面创建时，请求详情数据
        this.details();
    },
    activated() {
        // 页面激活时，重新请求详情数据
        this.id = this.$route.query.id;
        this.details();
    }
}
</script>

<style scoped>
.details {
    text-align: left;

    .briefIntroduction {
        width: 85%;
        margin: 10px auto;
        padding: 20px 10px;
    }

    .content {
        width: 90%;
        margin: 40px auto;
    }

    img {
        width: auto !important;
        max-height: 500px !important;
        cursor: pointer; /* 添加鼠标指针样式，表明图片可点击 */
    }
}
</style>
