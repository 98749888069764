<template>
    <div id="vip">
        <el-row :gutter="20">
            <el-col :span="6" v-for="(item,index) in vipList" :key="index">
                <el-card class="card" shadow="hover">
                    <div class="head" v-bind:class="{ 'bg2': item.durationDays > 100000 }">
                    <div>{{item.name}}</div>
                    </div>
                    <div class="body">
                        <div class="priceBox">
                            <span class="price">{{item.price}}</span><span class="mini">USDT</span>
                        </div>
                        <el-tag type="warning" effect="plain" size="medium">{{item.sustained}}</el-tag>
                        <div class="info">
                            <p class="price-day" v-if="item.durationDays>100000">尊享VIP会员特权【永久】</p>
                            <p class="price-day" v-else>尊享VIP会员特权 {{item.durationDays}} 天</p>
                            <div class="li">可获取专属免费资源</div>
                            <div class="li">解锁会员专属功能</div>
                            <div class="li">每日免费下载限制扩展至 <span class="price-day">{{item.dailyRestrictions}}</span> 条</div>
                        </div>
                        <div class="buy">
                            <el-button round type="warning" v-if="item.durationDays>100000" @click="buy(item)">立即开通</el-button>
                            <el-button round v-else @click="buy(item)">立即开通</el-button>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <!-- 付款界面 -->
        <el-dialog :title="'开通'+buyItem.name" :visible.sync="dialogPayVisible" top="6vh">
            <img :src="payInfo.payImg" alt="" class="payImg">
            <el-descriptions title="VIP信息" class="downloadInfo">
                <el-descriptions-item label="VIP名称">{{buyItem.name}}</el-descriptions-item>
                <el-descriptions-item label="时长">{{buyItem.sustained}}</el-descriptions-item>
                <el-descriptions-item label="价格">
                    <div v-if="buyItem.price">
                        <el-tag size="small" effect="plain" type="success">{{ buyItem.price }} USDT</el-tag>
                    </div>
                    <div v-else>
                        <el-tag size="mini" effect="plain" type="info">联系客服询价</el-tag>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="用户">
                    <div v-if="userinfo">
                        {{userinfo.email}}
                    </div>
                    <div v-else>
                        未登录
                    </div>
                </el-descriptions-item>
            </el-descriptions>
            <div class="payText">{{payInfo.payText}}</div>
            <span slot="footer" class="dialog-footer"><el-button round @click="drawer = true">上传支付凭证</el-button></span>
        </el-dialog>

        <!-- 上传凭证 -->
        <el-drawer
            :title="'上传购买'+buyItem.name+'凭证'"
            :visible.sync="drawer"
            size="40%"
            :before-close="handleClose">
            <div class="uploadBox">
                <div v-if="upload" class="upload">
                    <el-upload
                        class="upload-demo"
                        drag
                        action="admin/api/common/upload"
                        :headers="headers"
                        :on-error="upError"
                        :on-success="upSuccess"
                        multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将凭证图片拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传图片文件，且不超过2Mb</div>
                    </el-upload>
                </div>
                <div v-if="!upload" class="uploadImg">
                    <el-image
                        class="img"
                        :src="voucherImage.fullurl"
                        :preview-src-list="[voucherImage.fullurl]">
                    </el-image>
                    <span>{{voucherImage.fullurl}}</span>
                </div>
                <el-form v-loading="loading">
                    <el-input type="textarea" v-model="voucherRemarks" :rows="3" placeholder="如您需对此凭证进行备注，请在此处输入备注。备注非必填"></el-input>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitVouchers" :loading="loading" :disabled="loading">{{ loading ? '提交中 ...' : '提交凭证进行审核' }}</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
// 这里放置你的导入项
import axios from "axios";

export default {
    // 注册组件
    components: {},
    // 定义 props
    props: {},
    // 数据
    data() {
        return {
            vipList:{}, // 会员列表
            payInfo:{payImg:'',payText:''}, // 支付信息
            buyItem:{}, // 购买信息
            dialogPayVisible:false, // 显示隐藏 支付界面
            drawer: false, // 上传凭证界面 显示隐藏
            voucherImage:{fullurl:'',url:''}, // 凭证图片
            upload:true, // 是否隐藏上传
            voucherRemarks:'', // 凭证备注
            loading:false, // 提交凭证 切换成 加载
            userinfo:{}, // 用户信息
            headers:{token:''}, // 标头
        }
    },
    // 计算属性
    computed: {},
    // 监听数据变化
    watch: {},
    // 方法
    methods: {
        // 提交凭证
        submitVouchers(){
            if (this.loading) {
                return;
            }
            if (!this.voucherImage.url.trim()) {
                this.$message.warning("凭证不能为空");
                return
            }
            this.$confirm('确认无误提交您的凭证?', '提示', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true; // 按钮加载
                // 提交后台
                axios.post('admin/api/index/addOrder.html', {
                    voucher: this.voucherImage.url, // 凭证
                    voucherRemarks: this.voucherRemarks, // 凭证备注
                    type: "开通VIP", // 类型
                    name: this.buyItem.name, // 名称
                    productID: this.buyItem.id // 产品id
                }, { headers: { 'token': this.userinfo.token } })
                    .then(res => {
                        console.log("res",res);
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    // 初始化
                    this.drawer = false; // 上传凭证界面 显示隐藏
                    this.voucherImage = {fullurl:'',url:''}; // 凭证图片
                    this.upload=true; // 是否隐藏上传
                    this.voucherRemarks=''; // 凭证备注
                    this.loading=false; // 提交凭证 切换成 加载
                });
            })
        },
        // 上传失败触发
        upError(err, file) {
            console.log('上传失败', JSON.parse(err.message))
            if (file.status == 'fail') {
                this.$message.error(JSON.parse(err.message).msg)
            }
        },
        // 上传成功触发
        upSuccess(res, file) {
            console.log('上传成功', res)
            console.log('file.status', file.status)
            if (file.status == 'success') {
                this.$message.success(res.msg)
                this.voucherImage = res.data
                this.upload = false
            }
        },
        // 关闭上传凭证前的回调
        handleClose(done) {
            this.$confirm('还有未上传的凭证哦 确定关闭吗？')
                .then(res => { // 确定关闭
                    done();
                    console.log("确定关闭",res);
                    // 初始化
                    this.voucherImage = {fullurl:'',url:''}; // 凭证图片
                    this.upload=true; // 是否隐藏上传
                    this.voucherRemarks=''; // 凭证备注
                    this.loading=false; // 提交凭证 切换成 加载
                }).catch(res => { // 取消关闭
                    console.log("取消关闭",res);
                });
        },
        // 开通vip
        buy(item){
            if(!this.userinfo){
                this.$message.warning("请先登录账号");
                return
            }
            this.headers = {token:this.userinfo.token}; // 配置标头
            this.buyItem = item
            this.dialogPayVisible = true // 显示支付界面
        },
        // 获取vip列表
        getAllVip(){
            axios.post('admin/api/index/getAllVip.html')
                .then(res => {
                    console.log("res",res);
                    if (res.data.code == 1) {
                        this.vipList = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(error => {
                console.error(error);
            }).finally(() => {
            });
        },
    },
    // 生命周期钩子
    created() {
        this.getAllVip() // 获取vip列表

        // 用户信息
        this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
        // 获取支付信息
        axios.post('admin/api/index/getPayInfo.html')
            .then(res => {
                this.payInfo = res.data.data;
            });
    },
    mounted() {
        // 组件挂载后调用
    },
    beforeCreate() {
        // 组件创建前调用
    },
    beforeMount() {
        // 组件挂载前调用
    },
    beforeUpdate() {
        // 组件更新前调用
    },
    updated() {
        // 组件更新后调用
    },
    beforeDestroy() {
        // 组件销毁前调用
    },
    destroyed() {
        // 组件销毁后调用
    },
    activated() {
        // keep-alive 组件被激活时调用
    },
    deactivated() {
        // keep-alive 组件被停用时调用
    }
}
</script>

<style scoped>
#vip{
    text-align: left;
}
/deep/ .el-card__body{
    padding: 0;
}
.card{
    margin-bottom: 30px;
    text-align: center;
    font-family: "system-ui";
    .head{
        background-color: #0cbc87bf;
        font-size: 24px;
        color: #fff;
        font-weight: 700;
        letter-spacing: 2px;
        padding: 20px 0;
    }
    .body{
        padding-top: 20px;
        padding-bottom: 50px;
        .priceBox{
            margin: 10px auto;
            .price{
                font-size: 30px;
                font-weight: 700;
            }
            .mini{
                font-size: 12px;
                color: #737373;
                vertical-align: super;
                margin-left: 3px;
            }
        }
        .info{
            .price-day{
                font-size: 15px;
                color: #ff6f00;
            }
            .li{
                font-size: 14px;
                color: #666;
                margin-bottom: 8px;
            }
        }
        .buy{
            margin-top: 40px;
        }
    }
}

.bg2{
    background-color: #f7c32ebf !important;
}
/deep/ .el-button.is-round{
    padding:12px 50px;
}
/deep/ .el-dialog__body{
    text-align: center;
}
.payImg{
    display: inline-block;
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
}
.downloadInfo{
    width: 70%;
    margin: auto auto 10px;
}
.payText{
    font-size: 20px;
    font-weight: bold;
}

.uploadBox{
    margin: 10px 30px;
    .upload{
        margin: auto auto 20px;
    }
    .dialog-footer{
        margin-top: 20px;
    }
    .uploadImg{
        text-align: center;
        .img{
            width: 250px;
        }
        span{
            font-size: 12px;
            color: #666666;
            word-wrap: break-word; /* 当单词过长时自动换行 */
            margin: 10px 5px;
            display: block;
            text-align: left;
        }
    }
}
</style>
