import Vue from 'vue';
import ElementUI from 'element-ui';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router'

Vue.use(ElementUI);
Vue.use(Element, { size: 'small', zIndex: 3000 });

// 全局过滤器，用于将日期格式化为年月日
Vue.filter('formatDate', function(value) {
  if (!value) return '';
  // 将日期字符串按空格分割成数组，并取第一个元素（年月日部分）
  return value.split(' ')[0];
});

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});