<template>
    <div id="register">
        <el-row>
            <el-col :span="8" :push="8">
                <h1 class="title">用户注册</h1>
                <el-form label-position="left" label-width="80px" :model="form" ref="form">
                    <el-form-item label="邮箱" prop="email" :rules="[{ required: true, message: '邮箱不能为空'},]">
                        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '密码不能为空'},]">
                        <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code" :rules="[{ required: true, message: '验证码不能为空'},]">
                        <el-input v-model="form.code" placeholder="请输入验证码" style="width: 53%"></el-input>
                        <img class="captcha-img" alt="Captcha" :src="codeSrc" @click="randomNumber">
                    </el-form-item>
                    <el-form-item>
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="to"
                        >已有账号
                        </el-link>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('form')" :disabled="submitLoad">注册</el-button>
                        <el-button @click="resetForm('form')">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
import axios from "axios";

export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    props: {},
    data() {
        // 这里存放数据
        return {
            form: {
                email: '',
                password: '',
                code: '',
            },// 表单数据
            submitLoad: false, // 提交加载
            codeSrc: "admin/captcha.html", // 随机数验证码
        }
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 重新生成验证码
        randomNumber() {
            this.codeSrc = `admin/captcha.html?r=${Math.random()}`
        },
        // 跳转
        to() {
            this.$router.push({path: '/login'});
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        // 提交注册
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log("valid", valid);
                    this.submitLoad = true; // 按钮加载
                    // 登录
                    axios.post('admin/api/user/register.html', this.form)
                        .then(res => {
                            console.log("res", res);
                            if (res.data.code == 1) {
                                this.$message.success(res.data.msg);
                                // 存储用户登录状态
                                localStorage.setItem('userinfo', JSON.stringify(res.data.data.userinfo));
                                location.reload() // 刷新页面
                            } else {
                                this.$message.error(res.data.msg);
                                this.randomNumber(); // 刷新验证码
                            }
                        }).catch(error => {
                        console.error(error);
                    }).finally(() => {
                        this.submitLoad = false; // 按钮结束加载
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 检测登录
        isLogin() {
            const userinfo = JSON.parse(localStorage.getItem('userinfo'));
            if (userinfo) this.$router.push({path: '/'}); // 前往首页
        }
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.isLogin() // 检测登录
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
    },
    beforeCreate() {
    },
    beforeMount() {
    }, // 生命周期 - 挂载之前
    beforeUpdate() {
    }, // 生命周期 - 更新之前
    updated() {
    }, // 生命周期 - 更新之后
    beforeDestroy() {
    }, // 生命周期 - 销毁之前
    destroyed() {
    }, // 生命周期 - 销毁完成
    activated() {
    } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>

<style scoped>
#register {
    text-align: left;
}

.title {
    margin: 0 auto 40px auto;
    color: #505458;
    font-family: cursive;
    text-align: center;
    font-size: 36px;
}

.captcha-img {
    position: absolute;
    bottom: 0;
    margin-left: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>
